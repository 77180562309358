<template>
  <ButtonIcon
    :field="camelCase('toggle', 'feedback', field)"
    :hide="!resposta.parecer"
    :icon="icon"
    :tooltip="tooltip"
    @click="handleClick"
  />
</template>

<script>
import { ButtonIcon, camelCase } from "@/lib";

export default {
  components: { ButtonIcon },
  computed: {
    icon() {
      return `${this.showDetails ? "chevron-up" : "chevron-down"}`;
    },
    tooltip() {
      const predicate = "parecer do revisor";
      const verb = this.showDetails ? "ocultar" : "exibir";
      return `${verb} ${predicate}`;
    },
  },
  methods: {
    camelCase,
    handleClick() {
      this.$emit("update:showDetails", !this.showDetails);
    },
  },
  name: "FeedbackToggle",
  props: {
    field: {
      default: null,
      type: [String, Number],
    },
    resposta: {
      default: () => ({}),
      type: Object,
    },
    showDetails: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
