<template>
  <AsyncExecute v-slot="{ isBusy, execute, lastError }">
    <OverlayBase :is-busy="isBusy">
      <DescriptionText>Aproveite para registrar um dispositivo agora e na próxima vez que for entrar no NeoSigner poderá utilizar um método mais simples de autenticação.</DescriptionText>
      <DialogForm
        :external-errors="lastError"
        @save="execute(handleRegister)"
      >
        <template #actions>
          <ButtonSecondary
            label="Talvez mais tarde"
            field="later"
            @click="handleLater"
          />
          <ButtonPrimary
            label="Registrar meu dispositivo"
            field="register"
          />
        </template>
      </DialogForm>
    </OverlayBase>
  </AsyncExecute>
</template>

<script>
import { AppError, AsyncExecute, ButtonPrimary, ButtonSecondary, DescriptionText, DialogForm, OverlayBase, SYSLOG_SEVERITIES } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    AsyncExecute,
    ButtonPrimary,
    ButtonSecondary,
    DescriptionText,
    DialogForm,
    OverlayBase,
  },
  computed: {
    ...mapGetters("auth", ["isWebAuthnSupported"]),
  },
  methods: {
    ...mapMutations("auth", ["setWebAuthnRegistrationDone"]),
    ...mapActions("auth", ["registerAuthenticator"]),
    handleLater() {
      this.setWebAuthnRegistrationDone();
    },
    async handleRegister() {
      try {
        await this.registerAuthenticator();
      }
      catch (error) {
        // como parte do processo de registro é feito no navegador, o erro pode ter muitas causas desde uma simples dispensa do usuário ou algo mais grave como um bug no processo de integração com backend. assumesse aqui o nível crítico para estimular o registro e análise do registro de log.
        const severity = error.severity || SYSLOG_SEVERITIES.CRITICAL;

        AppError.throw({
          cause: error,
          message: "Não foi possível registrar o dispositivo. Tente novamente ou siga em frente para entrar no sistema",
          meta: { property: "form" },
          severity,
        });
      }
    },
  },
  mounted() {
    if (!this.isWebAuthnSupported) {
      this.handleLater();
    }
  },
  name: "RegistrationDialog",
};
</script>
