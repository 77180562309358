import { ROUTING_ACCESSES, listCrumbsStakeholders } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./stakeholders-edit-page.vue"
  );

export function createStakeholdersEditRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER, ROUTING_ACCESSES.REQUESTER],
      crumbs: [...listCrumbsStakeholders(), { text: "Edição de Interessado" }],
      layout: "LayoutNav",
    },
    path: "/stakeholders/edit/:itemId",
    props: true,
  };
}
