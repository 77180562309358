// código a seguir é uma adaptção dos scripts dispnibilizados em "https://www.assinadorserpro.estaleiro.serpro.gov.br/minimalista/js/lib/serpro/serpro-signer-promise.js" e ""https://www.assinadorserpro.estaleiro.serpro.gov.br/minimalista/js/lib/serpro/serpro-signer-client.js" que originalmente eram impotados em index.html. entretanto, o processo de importação envolve cookies o que estava disparando alertas e erros no navegador que eventualmente poderiam interromper a importação e comprometer o uso. a adpatção também removeu a dependencia ao jquery 3, que era importada de https://code.jquery.com/jquery-3.1.1.slim.min.js".

window.SerproSignerClient = (function () {
  var ws;
  var defer = [];
  var uriServer = "wss://127.0.0.1:65156/signer/";
  var uriServerVerify = "http://127.0.0.1:65056/";
  var isDebug = false;

  var l = function (message) {
    if (isDebug) console.info(message);
  };

  var SerproSignerPromise = function () {
    var callbackSuccess = null;
    var callbackError = null;

    this.success = function (cbSucess) {
      callbackSuccess = cbSucess;
      return this;
    };

    this.error = function (cbError) {
      callbackError = cbError;
      return this;
    };

    this.resolve = function (value) {
      callbackSuccess(value);
    };

    this.hasCallbackError = function () {
      return (callbackError === null ? false : true);
    };

    this.reject = function (value) {
      if (callbackError) {
        callbackError(value);
      }
    };
  };

  var services = {
    attached: function (inputSignature) {
      var signerCommand = {
        command: "attached",
        inputSignature: inputSignature,
      };
      var promise = services.execute(signerCommand);
      return promise;
    },

    connect: function (callbackOpen, callbackClose, callbackError) {
      if (ws == null || ws.readyState != 1) {
        l("Connecting on " + uriServer);
        ws = new WebSocket(uriServer);

        ws.onopen = function (msg) {
          if (callbackOpen)
            callbackOpen(msg.target.readyState);
        };

        ws.onclose = function (msg) {
          if (callbackClose)
            callbackClose(msg.target.readyState);
        };

        ws.onmessage = function (response) {
          var objResponse = JSON.parse(response.data);

          if (objResponse !== undefined && objResponse.error !== undefined) {
            if (objResponse.requestId !== undefined && defer[objResponse.requestId].hasCallbackError()) {
              defer[objResponse.requestId].reject(objResponse);
            }
            else if (callbackError) {
              callbackError(objResponse);
            }
          }
          else {
            l("Receiving command with ID [" + objResponse.requestId + "]");

            if (objResponse.requestId != undefined && defer[objResponse.requestId] !== undefined) {
              defer[objResponse.requestId].resolve(objResponse);
            }
            else {
              l("No callback to success was defined.");
              l(objResponse);
            }
          }

          if (objResponse.requestId !== undefined) {
            delete defer[objResponse.requestId];
          }
        };

        ws.onerror = function (response) {
          if (defer[response.requestId] !== undefined) {
            defer[response.requestId].reject(response);
          }
          else {
            l("No callback to success was defined. Generic error.");
            callbackError(response);
          }

          if (response.requestId !== undefined) {
            delete defer[response.requestId];
          }
        };
      }
    },

    execute: function (request) {
      if (!services.isConnected()) {
        var errorId = new Date().getTime();
        defer[errorId] = new SerproSignerPromise();

        var intervalError = setInterval(function () {
          defer[errorId].reject({ error: "A connection to the sign server has not been started" });
          delete defer[errorId];
          clearInterval(intervalError);
        }, 500);

        return defer[errorId];
      }
      else {
        if (request.requestId === undefined || request.requestId === "") {
          request.requestId = new Date().getTime();
        }

        l("Sending command [" + request.command + "] with ID [" + request.requestId + "] to URI [" + uriServer + "]");
        l(request);

        defer[request.requestId] = new SerproSignerPromise();

        ws.send(JSON.stringify(request));

        return defer[request.requestId];
      }
    },

    isConnected: function () {
      if (ws != null)
        return ws.readyState == 1 ? true : false;
      return false;
    },

    list: function () {
      var listCommand = {
        command: "list",
      };
      var promise = services.execute(listCommand);
      return promise;
    },

    setDebug: function (isToDebug) {
      l("Setting debug on to " + (isToDebug ? "ON" : "OFF"));
      isDebug = isToDebug;
    },

    setUriServer: function (uriProtocol, uriDns, uriPort, uriPath) {
      var uri = uriProtocol + "://" + uriDns + ":" + uriPort + uriPath;
      l("Setting URI to " + uri);
      uriServer = uri;
    },

    sign: function (type, inputData, textEncoding, outputDataType, attached) {
      var signerCommand = {
        attached: attached,
        command: "sign",
        inputData: inputData,
        outputDataType: outputDataType,
        textEncoding: textEncoding,
        type: type,
      };
      var promise = services.execute(signerCommand);
      return promise;
    },

    uriServerVerify: function (uriProtocol, uriDns, uriPort, uriPath) {
      var uri = uriProtocol + "://" + uriDns + ":" + uriPort + uriPath;
      l("Setting URI Verifycation to " + uri);
      uriServerVerify = uri;
    },

    verify: function (type, inputData, inputSignature, textEncoding, algorithmOIDHash) {
      console.info("###", arguments);
      var signerCommand = {
        algorithmOIDHash: algorithmOIDHash,
        command: "verify",
        inputData: inputData,
        inputSignature: inputSignature,
        textEncoding: textEncoding,
        type: type,
      };
      var promise = services.execute(signerCommand);
      return promise;
    },

    verifyIsInstalledAndRunning: function () {
      var requestIdVerify = "error";

      if (defer[requestIdVerify] != undefined) {
        defer[requestIdVerify].reject("Other request is running");
      }

      defer[requestIdVerify] = new SerproSignerPromise();

      var imageVerify = new Image();

      imageVerify.onload = function () {
        l("App installed and running");
        defer[requestIdVerify].resolve(true);
        delete defer[requestIdVerify];
      };

      imageVerify.onerror = function () {
        l("App not installed and not running");
        defer[requestIdVerify].reject(false);
        delete defer[requestIdVerify];
      };

      imageVerify.src = uriServerVerify + "verify.gif?t=" + new Date().getTime();

      return defer[requestIdVerify];
    },

  };

  return services;
})();
