<template>
  <ToolbarButtons>
    <PdfDocumentToolbarZoom
      :field="camelCase('zoom', field)"
      :disabled="disabled"
      :zoom="zoom"
      v-on="$listeners"
    />
    <div>
      <PdfDocumentToolbarPages
        :field="camelCase('pages', field)"
        :disabled="disabled"
        :pages-total="pagesTotal"
        :pages-current="pagesCurrent"
        v-on="$listeners"
      />
      <FileButtonDownload
        :field="camelCase('download', field)"
        :disabled="disabled"
        :download-blob="downloadBlob"
        :file-name="fileName"
      />
    </div>
  </ToolbarButtons>
</template>

<script>
import { camelCase } from "@/lib/strings";

import { ToolbarButtons } from "../buttons";
import FileButtonDownload from "./file-button-download";
import PdfDocumentToolbarPages from "./pdf-document-toolbar-pages";
import PdfDocumentToolbarZoom from "./pdf-document-toolbar-zoom";

export default {
  components: {
    FileButtonDownload,
    PdfDocumentToolbarPages,
    PdfDocumentToolbarZoom,
    ToolbarButtons,
  },
  methods: {
    camelCase,
  },
  name: "PdfDocumentToolbar",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    downloadBlob: {
      default: null,
      type: Function,
    },
    field: {
      default: "toolbar",
      type: String,
    },
    fileName: {
      default: "",
      type: String,
    },
    pagesCurrent: {
      default: null,
      type: Number,
    },
    pagesTotal: {
      default: null,
      type: Number,
    },
    zoom: {
      default: 100,
      type: Number,
    },
  },
};
</script>
