var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hide
    ? _c(
        "BButton",
        _vm._b(
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.hover.bottom",
                modifiers: { hover: true, bottom: true },
              },
            ],
            attrs: {
              id: _vm.id,
              type: _vm.type,
              title: _vm.parsedTooltip,
              disabled: _vm.consolidatedDisabled,
              pill: !_vm.squared,
              name: "action",
              value: _vm.field,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          },
          "BButton",
          _vm.$attrs,
          false
        ),
        [
          _vm.iconConfig.show
            ? _c("AppIcon", {
                attrs: {
                  name: _vm.iconConfig.name,
                  animation: _vm.iconConfig.animation,
                  size: _vm.iconSize,
                },
              })
            : _vm._e(),
          _vm._v("\n  " + _vm._s(_vm.consolidatedLabel) + "\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }