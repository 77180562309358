export function enablePersistence({ size = 10, storageKey = "LOGGER" } = {}) {
  const list = () => {
    const logs = localStorage.getItem(storageKey);
    return logs ? JSON.parse(logs) : [];
  };

  const save = (data) => {
    const logs = list();
    while (logs.length >= size) logs.pop();
    logs.unshift(data);
    localStorage.setItem(storageKey, JSON.stringify(logs));
  };

  const clear = () => {
    localStorage.removeItem(storageKey);
  };

  return { clear, list, save };
}
