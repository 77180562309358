var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.FieldSet,
    { attrs: { legend: "Webhook" } },
    [
      !_vm.disabled
        ? _c(_setup.AlertText, {
            attrs: {
              text: "A seguir você pode cadastrar os dados de um webhook que será acionado após a conclusão dos fluxos de assinaturas criados por este requisitante.",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        _setup.RowForm,
        [
          _c(
            _setup.ColBase,
            { attrs: { sm: "8" } },
            [
              _c(_setup.ControlInput, {
                attrs: {
                  field: "url",
                  rules: _setup.hasWebhook ? "required|url" : null,
                  label: "URL",
                  "helper-text":
                    "URL do webhook a ser notificado após conclusão de fluxos",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _setup.localValue.url,
                  callback: function ($$v) {
                    _vm.$set(_setup.localValue, "url", $$v)
                  },
                  expression: "localValue.url",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            _setup.ColBase,
            { attrs: { sm: "4" } },
            [
              _c(_setup.ControlInput, {
                attrs: {
                  field: "method",
                  rules: _setup.hasWebhook ? "required" : null,
                  label: "Método",
                  "helper-text": "Método HTTP a ser utilizado na notificação",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _setup.localValue.method,
                  callback: function ($$v) {
                    _vm.$set(_setup.localValue, "method", $$v)
                  },
                  expression: "localValue.method",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.disabled || _setup.localValue.apikeyHas
        ? _c(
            _setup.FieldSet,
            { attrs: { legend: "ApiKey", divider: "" } },
            [
              !_vm.disabled
                ? _c(
                    _setup.RowForm,
                    [
                      _c(
                        _setup.ColBase,
                        [
                          _c(_setup.ControlCheckbox, {
                            attrs: {
                              placeholder: "Autenticar usando Apikey",
                              field: _setup.camelCase("apikey", "has"),
                            },
                            model: {
                              value: _setup.localValue.apikeyHas,
                              callback: function ($$v) {
                                _vm.$set(_setup.localValue, "apikeyHas", $$v)
                              },
                              expression: "localValue.apikeyHas",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                _setup.RowForm,
                [
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("apikey", "name"),
                          rules: _setup.localValue.apikeyHas
                            ? "required"
                            : null,
                          label: "Nome",
                          "helper-text": "Nome da chave da API",
                          disabled:
                            !_setup.localValue.apikeyHas || _vm.disabled,
                        },
                        model: {
                          value: _setup.localValue.apikeyName,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "apikeyName", $$v)
                          },
                          expression: "localValue.apikeyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("apikey", "value"),
                          disabled:
                            !_setup.localValue.apikeyHas || _vm.disabled,
                          rules: _setup.localValue.apikeyHas
                            ? "required"
                            : null,
                          label: "Valor",
                          "helper-text": "Valor da chave da API",
                        },
                        model: {
                          value: _setup.localValue.apikeyValue,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "apikeyValue", $$v)
                          },
                          expression: "localValue.apikeyValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled || _setup.localValue.oauth2Has
        ? _c(
            _setup.FieldSet,
            { attrs: { legend: "Oauth2", divider: "" } },
            [
              !_vm.disabled
                ? _c(
                    _setup.RowForm,
                    [
                      _c(
                        _setup.ColBase,
                        [
                          _c(_setup.ControlCheckbox, {
                            attrs: {
                              placeholder: "Autenticar usando OAuth2",
                              field: _setup.camelCase("oauth2", "has"),
                            },
                            model: {
                              value: _setup.localValue.oauth2Has,
                              callback: function ($$v) {
                                _vm.$set(_setup.localValue, "oauth2Has", $$v)
                              },
                              expression: "localValue.oauth2Has",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                _setup.RowForm,
                [
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("oauth2", "url"),
                          disabled:
                            !_setup.localValue.oauth2Has || _vm.disabled,
                          rules: _setup.localValue.oauth2Has
                            ? "required|url"
                            : null,
                          label: "Url",
                          "helper-text": "URL para obtenção do token OAuth2",
                        },
                        model: {
                          value: _setup.localValue.oauth2Url,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "oauth2Url", $$v)
                          },
                          expression: "localValue.oauth2Url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.RowForm,
                [
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("oauth2", "client"),
                          rules: _setup.localValue.oauth2Has
                            ? "required"
                            : null,
                          disabled:
                            !_setup.localValue.oauth2Has || _vm.disabled,
                          label: "Cliente",
                          "helper-text": "Identificação do cliente OAuth2",
                        },
                        model: {
                          value: _setup.localValue.oauth2Client,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "oauth2Client", $$v)
                          },
                          expression: "localValue.oauth2Client",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("oauth2", "secret"),
                          rules: _setup.localValue.oauth2Has
                            ? "required"
                            : null,
                          disabled:
                            !_setup.localValue.oauth2Has || _vm.disabled,
                          label: "Segredo",
                          "helper-text":
                            "Segredo do cliente OAuth2 para obtenção do token",
                        },
                        model: {
                          value: _setup.localValue.oauth2Secret,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "oauth2Secret", $$v)
                          },
                          expression: "localValue.oauth2Secret",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                _setup.RowForm,
                [
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("oauth2", "type"),
                          disabled:
                            !_setup.localValue.oauth2Has || _vm.disabled,
                          rules: _setup.localValue.oauth2Has
                            ? "required"
                            : null,
                          label: "Tipo",
                          "helper-text": "Tipo de autenticação OAuth2",
                        },
                        model: {
                          value: _setup.localValue.oauth2Type,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "oauth2Type", $$v)
                          },
                          expression: "localValue.oauth2Type",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    _setup.ColBase,
                    { attrs: { sm: "" } },
                    [
                      _c(_setup.ControlInput, {
                        attrs: {
                          field: _setup.camelCase("oauth2", "scope"),
                          disabled:
                            !_setup.localValue.oauth2Has || _vm.disabled,
                          label: "Escopo",
                          "helper-text": "Escopo da autenticação OAuth2",
                        },
                        model: {
                          value: _setup.localValue.oauth2Scope,
                          callback: function ($$v) {
                            _vm.$set(_setup.localValue, "oauth2Scope", $$v)
                          },
                          expression: "localValue.oauth2Scope",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }