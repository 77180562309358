<template>
  <OverlayBase :is-busy="isSearching">
    <AlertText
      v-if="!isEditMode"
      text="O Tipo e o Código do usuário não poderão ser alterados no futuro"
    />
    <RowForm>
      <ColBase sm="4">
        <ControlSelectSingle
          :field="camelCase('tipoCodigo', field)"
          :value="tipoCodigo"
          :options="['CPF', 'GENERICO']"
          :disabled="!arePrimaryFieldsEnabled"
          label="tipo"
          @input="$emit('update:tipoCodigo', $event)"
        />
      </ColBase>
      <ColBase sm="8">
        <ControlInput
          :field="camelCase('codigo', field)"
          :value="codigo"
          :rules="`required${tipoCodigo === 'CPF' ? '|cpf' : ''}`"
          :mask="tipoCodigo === 'CPF' ? '###.###.###-##' : null"
          :disabled="!arePrimaryFieldsEnabled"
          label="código"
          :lookup-options="lookupOptions"
          lookup-text-field="codigo"
          lookup-value-field="nome"
          @lookup="handleLookup"
          @input="$emit('update:codigo', $event)"
          @blur="handleCodigoSearch"
        />
      </ColBase>
    </RowForm>
    <AlertText
      v-if="!isEditMode && tipoCodigo === 'GENERICO'"
      variant="info"
      text="Usuário do tipo GENERICO não pode assinar fluxos que requeiram uso de certificado ICP-Brasil"
    />
    <RowForm>
      <ColBase sm>
        <ControlInput
          :field="camelCase(field, 'nome')"
          :value="nome"
          :disabled="!arePrimaryFieldsEnabled"
          rules="required|min:3|max:255"
          label="Nome"
          :lookup-options="lookupOptions"
          lookup-text-field="nome"
          lookup-value-field="codigo"
          @lookup="handleLookup"
          @input="$emit('update:nome', $event)"
        />
      </ColBase>
    </RowForm>

    <RowForm>
      <ColBase sm>
        <ControlEmail
          :value="email"
          :field="camelCase(field, 'email')"
          :disabled="!areAllFieldsEnabled"
          rules="required"
          :label="emailLabel"
          :lookup-options="lookupOptions"
          lookup-text-field="email"
          lookup-value-field="nome"
          @lookup="handleLookup"
          @input="$emit('update:email', $event)"
        />
      </ColBase>
      <ColBase sm>
        <ControlCelular
          :value="celular"
          :field="camelCase(field, 'celular')"
          :disabled="!areAllFieldsEnabled"
          :label="celularLabel"
          @input="$emit('update:celular', $event)"
        />
      </ColBase>
    </RowForm>
  </OverlayBase>
</template>

<script>
import {
  AlertText,
  ColBase,
  ControlCelular,
  ControlEmail,
  ControlInput,
  ControlSelectSingle,
  OverlayBase,
  RowForm,
  camelCase,
  useAsyncSearch,
} from "@/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    AlertText,
    ColBase,
    ControlCelular,
    ControlEmail,
    ControlInput,
    ControlSelectSingle,
    OverlayBase,
    RowForm,
  },
  computed: {
    ...mapGetters("contas", { contas: "getUniqueActiveContas" }),
    areAllFieldsEnabled() {
      return !this.hasSuccessfullyLookupConta;
    },
    arePrimaryFieldsEnabled() {
      return (
        this.areAllFieldsEnabled
        && !this.isEditMode
        && !this.hasSuccessfullySearchedCodigo
      );
    },
    hasSuccessfullySearchedCodigo() {
      return this.searchResult && this.searchResult.status === "success";
    },
    isCodigoSearchEnabled() {
      return !this.isEditMode && !this.hasSuccessfullyLookupConta;
    },
    isLookupEnabled() {
      return (
        !this.isEditMode
        && !this.hasSuccessfullySearchedCodigo
        && !this.hasSuccessfullyLookupConta
        && !this.disableLookup
      );
    },
    lookupOptions() {
      return this.isLookupEnabled ? this.contas : null;
    },
  },
  data() {
    return {
      hasSuccessfullyLookupConta: false,
    };
  },
  methods: {
    camelCase,
    async handleCodigoSearch() {
      if (!this.isCodigoSearchEnabled) return;
      await this.search(this.codigo);
      if (this.hasSuccessfullySearchedCodigo) {
        this.$emit("update:nome", this.searchResult.data.nome);
      }
    },
    handleLookup(conta) {
      if (!conta) return;

      this.hasSuccessfullyLookupConta = true;

      this.$emit("update:tipoCodigo", conta.tipoCodigo);
      this.$emit("update:codigo", conta.codigo);
      this.$emit("update:nome", conta.nome);
      this.$emit("update:email", conta.email);
      this.$emit("update:celular", conta.celular);
    },
    reset() {
      if (this.isEditMode) return;

      this.$emit("update:codigo", null);
      this.$emit("update:nome", null);
      this.$emit("update:email", null);
      this.$emit("update:celular", null);

      this.hasSuccessfullyLookupConta = false;
      this.resetSearchState();
    },
  },
  name: "AccountDialog",
  props: {
    celular: {
      default: null,
      type: String,
    },
    celularLabel: {
      default: "Celular",
      type: String,
    },
    codigo: {
      default: null,
      type: String,
    },
    disableLookup: {
      default: false,
      type: Boolean,
    },
    email: {
      default: null,
      type: String,
    },
    emailLabel: {
      default: "E-mail",
      type: String,
    },
    field: {
      required: true,
      type: String,
    },
    isEditMode: {
      default: false,
      type: Boolean,
    },
    nome: {
      default: null,
      type: String,
    },
    tipoCodigo: {
      default: "CPF",
      type: String,
    },
  },
  setup() {
    const searchApi = codigo => window.$store.dispatch("contas/search", codigo);
    const { isSearching, resetSearchState, search, searchResult } = useAsyncSearch(searchApi);
    return {
      isSearching,
      resetSearchState,
      search,
      searchResult,
    };
  },
};
</script>
