import { createCollectionStoreModule } from "@/lib";

import { parseGestorItemToRecord, parseGestorRecordToItem } from "../gestores-helpers";

export function createGestoresStore({ api, pageLimit, pollingSeconds }) {
  const baseEndpoint = "/v1/contas/perfil/GESTOR";

  return createCollectionStoreModule({
    api,
    editEndpoint: baseEndpoint,
    newEndpoint: baseEndpoint,
    pageLimit,
    parseItemToRecord: parseGestorItemToRecord,
    parseRecordToItem: parseGestorRecordToItem,
    pollingSeconds,
    queryEndpoint: baseEndpoint,
  });
}
