export function prepareUplodad(payload) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) =>
    formData.append(key, value),
  );

  return { formData, headers };
}
