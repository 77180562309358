<template>
  <ListPower
    field="approvals"
    :items="respostasForList"
    :fields="fields"
    :initial-sort="initialSort"
    :search-config="searchConfig"
  >
    <template #actions="{ item: resposta, index }">
      <FeedbackToggle
        :field="index"
        :resposta="resposta"
        :show-details.sync="resposta._showDetails"
      />
    </template>
    <template #row-details="{ item, index }">
      <InputRichText
        :id="camelCase('feedback', index)"
        v-model="item.parecer"
        disabled
      />
    </template>
  </ListPower>
</template>

<script>
import { isInteressadoWithStatusAprovado, isInteressadoWithStatusBloqueado, isInteressadoWithStatusPendente, isInteressadoWithStatusRejeitado } from "@/data";
import { InputRichText, ListPower, camelCase } from "@/lib";

import FeedbackToggle from "./feedback-toggle.vue";

export default {
  components: {
    FeedbackToggle,
    InputRichText,
    ListPower,
  },
  computed: {
    fields() {
      return [
        { key: "nome", label: "Nome", sortable: true },
        { key: "papel", label: "Papel", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "data", label: "Data", sortable: true },
      ];
    },
    initialSort() {
      return {
        direction: "asc",
        field: "data",
      };
    },
    respostasForList() {
      return this.respostas.map(r => ({
        ...r,
        _showDetails: false,
      }));
    },
    searchConfig() {
      return {
        enum: [
          {
            color: "warning",
            isEnum: isInteressadoWithStatusPendente,
            text: "Pendente",
          },
          {
            color: "secondary",
            isEnum: isInteressadoWithStatusBloqueado,
            text: "Bloqueado",
          },
          {
            color: "success",
            isEnum: isInteressadoWithStatusAprovado,
            text: "Aprovou",
          },
          {
            color: "danger",
            isEnum: isInteressadoWithStatusRejeitado,
            text: "Rejeitou",
          },
        ],
      };
    },
  },
  methods: {
    camelCase,
  },
  name: "ApprovalsList",
  props: {
    respostas: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
