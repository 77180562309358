import { ROUTING_ACCESSES, listCrumbsFlowsEngagement } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-engagement-serpro-id-page.vue"
  );

export function createFlowsEngagementSerproIdRoutes({ serproIdBaseUrl, serproIdClientId, serproIdRedirectPath }) {
  return [
    {
      component,
      meta: {
        access: [ROUTING_ACCESSES.STAKEHOLDER],
        crumbs: [...listCrumbsFlowsEngagement(), { text: "SerproId" }],
        layout: "LayoutNav",
      },
      path: "/flows-engagement/serpro-id/:fluxoId",
      props: route => ({
        fluxoId: route.params.fluxoId,
        responseSerproId: route.query.responseSerproId,
        serproIdBaseUrl,
        serproIdClientId,
        serproIdRedirectPath,
      }),
    },

    {
      // rota usada pelo serpro-id para responder pedidos de autorização ao neosigner
      path: serproIdRedirectPath,
      redirect: (to) => {
        const fluxoId = to.query.state;
        // a resposta do serpro-id contém o código de sucesso ou uma mensagem genérica de erro sinalizando a provável recusa de autorização do usuário
        const responseSerproId = to.query.code ?? "error";
        return {
          path: `/flows-engagement/serpro-id/${fluxoId}`,
          query: { responseSerproId },
        };
      },
    },
  ];
}
