import { createCollectionStoreModule } from "@/lib";

export function createGruposStore({ api, pageLimit, pollingSeconds }) {
  const baseEndpoint = "/v1/grupos";

  return createCollectionStoreModule({
    api,
    editEndpoint: baseEndpoint,
    getters: {
      membrosOfGrupo: (state, getters, rootState, rootGetters) => (grupoId) => {
        const grupo = getters.getItemById(grupoId);
        if (!grupo) return [];

        const { gestores: gestoresIds, requisitantes: requisitantesIds } = grupo;
        const requisitantes = rootGetters["requisitantes/filterItemsByFieldWithSomeValue"]("id", requisitantesIds);
        const gestores = rootGetters["gestores/filterItemsByFieldWithSomeValue"]("id", gestoresIds);

        const membros = requisitantes.concat(gestores);
        return membros;
      },
    },
    newEndpoint: baseEndpoint,
    pageLimit,
    pollingSeconds,
    queryEndpoint: baseEndpoint,
  });
}
