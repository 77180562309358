<template>
  <div>
    <ManagerSession />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { pickLayout } from "../layouts";
import ManagerSession from "./manager-session";

export default {
  components: {
    ManagerSession,
  },
  computed: {
    layout() {
      return pickLayout(this.$route.meta.layout);
    },
  },
  name: "App",
};
</script>
