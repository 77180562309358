import { asArray } from "@/lib";

export const PROFILES = {
  ADMINISTRATOR: "ADMINISTRADOR",
  MANAGER: "GESTOR",
  REQUESTER: "REQUISITANTE",
  STAKEHOLDER: "INTERESSADO",
};

export function isProfileAnyOf(profile, profileOrProfiles) {
  if (typeof profile !== "string") return false;

  const profiles = asArray(profileOrProfiles);
  return profiles.includes(profile.toUpperCase());
}

export function isProfileNoneOf(profile, profileOrProfiles) {
  return !isProfileAnyOf(profile, profileOrProfiles);
}
