import { hashEnvironmentValues, listEnvironmentValues } from "./enumerate";
import { getEnvironmentValue } from "./get";
import { isEnvironmentValue } from "./is";
import { loadSystemEnvironment } from "./system";

export function createEnvironment(source = {}) {
  const data = {
    ...source,
    ...loadSystemEnvironment(),
  };

  return {
    data,
    get: (name, value) => getEnvironmentValue({ data, name, value }),
    hash: () => hashEnvironmentValues(data),
    is: (name, options = {}) => isEnvironmentValue({ data, name, ...options }),
    list: () => listEnvironmentValues(data),
  };
}
