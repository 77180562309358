<script setup>
import { createDefaultRequester, groupsWithInactiveLabel, isManagerGroup, isRequesterPessoa, isRequesterSistema } from "@/data";
import { ButtonsSaveBack, ColBase, ControlCheckbox, ControlSelectMulti, ControlSelectSingle, DialogForm, FieldSet, RowForm, SpacerBase } from "@/lib";
import { computed } from "vue";

import { AccountDialog } from "../account-dialog";
import { WebhookFields } from "../webhook-fields";

const props = defineProps({
  defaultRequester: {
    default: createDefaultRequester,
    type: Object,
  },
  externalErrors: {
    default: null,
    type: Object,
  },
  groups: {
    default: () => ([]),
    type: Array,
  },
  isEditMode: {
    default: false,
    type: Boolean,
  },
  managerId: {
    default: null,
    type: String,
  },
});

const groupsAsOptions = computed(() => {
  // grupos que já fazem parte do requisitante devem aparecer na listagem mesmo que sem vinculo com o gestor atual
  const requesterGroups = props.defaultRequester.grupos || [];
  const isRequesterGroup = g => requesterGroups.find(id => g.id === id);

  return groupsWithInactiveLabel(props.groups)
    .filter(g => isManagerGroup(g, props.managerId) || isRequesterGroup(g))
    .map(g => ({
      ...g,
      // se o grupo não for do gestor deve aparecer desabilitado mesmo que ainda seja um grupo ativo
      disabled: !isManagerGroup(g, props.managerId),
    }));
});
</script>

<template>
  <DialogForm
    :default-payload="defaultRequester"
    :external-errors="externalErrors"
    :external-errors-field-map="{
      codigo: 'codigoRequisitante',
      'apikey.valor': 'apikeyValue',
      'apikey.chave': 'apikeyName',
      'oauth2.tokenUrl': 'oauth2Url',
      'oauth2.clientId': 'oauth2Client',
      'oauth2.clientSecret': 'oauth2Secret'
    }"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload }">
      <RowForm>
        <ColBase>
          <ControlSelectSingle
            v-model="payload.tipoConta"
            field="tipoConta"
            :disabled="isEditMode"
            :options="['PESSOA', 'SISTEMA']"
            rules="required"
            label="Tipo de Requisitante"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase>
          <ControlSelectMulti
            v-model="payload.grupos"
            field="grupos"
            :options="groupsAsOptions"
            value-field="id"
            text-field="nome"
            :rows="3"
            label="Grupos"
          />
        </ColBase>
      </RowForm>
      <FieldSet legend="dados da conta">
        <AccountDialog
          ref="accountDialog"
          field="requisitante"
          :is-edit-mode="isEditMode"
          :tipo-codigo.sync="payload.tipoCodigo"
          :codigo.sync="payload.codigo"
          :nome.sync="payload.nome"
          :email.sync="payload.email"
          :email-label="isRequesterPessoa(payload) ? 'E-mail' : 'E-mail do sistema'"
          :celular.sync="payload.celular"
          :celular-label="isRequesterPessoa(payload) ? 'Celular' : 'Celular de contato'"
        />
      </FieldSet>
      <template v-if="isRequesterSistema(payload)">
        <SpacerBase vertical="10" />
        <WebhookFields v-model="payload.webhook" />
      </template>
      <template v-if="isEditMode">
        <SpacerBase vertical="10" />
        <RowForm>
          <ColBase>
            <ControlCheckbox
              v-model="payload.ativo"
              field="ativo"
              placeholder="Ativar Requisitante"
              switch
            />
          </ColBase>
        </RowForm>
      </template>
    </template>
    <template #actions>
      <slot
        name="actions"
        :clear-account-data="$refs.accountDialog.reset"
      />
      <ButtonsSaveBack @back="$emit('back')" />
    </template>
  </DialogForm>
</template>
