var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DialogForm", {
    attrs: {
      "default-payload": _vm.defaultFluxo,
      "external-errors": _vm.externalErrors,
      "show-action-divider": "",
    },
    on: {
      save: function ($event) {
        return _vm.$emit("save", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ payload }) {
            return [
              _c("TabsAccordion", {
                attrs: { field: "fluxo" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ accordion }) {
                        return [
                          _c("FlowFormBasicTab", {
                            attrs: {
                              accordion: accordion,
                              disabled: _vm.editDisabled,
                              fluxo: payload,
                              show: _vm.initialTab === "basicos",
                            },
                          }),
                          _vm._v(" "),
                          _c("FlowFormStakeholdersTab", {
                            attrs: {
                              accordion: accordion,
                              hidden: _vm.hideDetails,
                              disabled: _vm.editDisabled,
                              fluxo: payload,
                              show: _vm.initialTab === "interessados",
                            },
                          }),
                          _vm._v(" "),
                          _c("FlowFormDocumentsTab", {
                            attrs: {
                              accordion: accordion,
                              hidden: _vm.hideDetails,
                              disabled: _vm.editDisabled,
                              fluxo: payload,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          },
        },
        {
          key: "actions",
          fn: function ({ payload }) {
            return [_vm._t("actions", null, { payload: payload })]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }