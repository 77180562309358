var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ButtonIcon", {
    attrs: {
      field: _vm.camelCase("del", "interessado", _vm.field),
      hide: _vm.hide,
      icon: "del",
      tooltip: "desvincular interessado",
    },
    on: { click: _vm.handleUnlink },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }