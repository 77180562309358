import Vue from "vue";

import App from "./app";

export function initApp({ api, environment, logger, router, store }) {
  const app = new Vue({
    components: { App },
    render: h => h(App),
    router,
    store,
  });

  // permite acesso dentro dos componentes vue
  Vue.prototype.$api = api;
  Vue.prototype.$environment = environment;
  Vue.prototype.$logger = logger;

  return app;
}
