<script setup>
import { computed } from "vue";

const props = defineProps({
  pathAttrs: {
    default: () => ({}),
    type: Object,
  },
  pathData: {
    default: "M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.5,8A1.5,1.5 0 0,1 17,9.5A1.5,1.5 0 0,1 15.5,11A1.5,1.5 0 0,1 14,9.5A1.5,1.5 0 0,1 15.5,8M8.5,8A1.5,1.5 0 0,1 10,9.5A1.5,1.5 0 0,1 8.5,11A1.5,1.5 0 0,1 7,9.5A1.5,1.5 0 0,1 8.5,8M12,17.5C9.67,17.5 7.69,16.04 6.89,14H17.11C16.3,16.04 14.33,17.5 12,17.5Z",
    type: String,
  },
  size: {
    default: 24,
    type: Number,
  },
});

const svgStyle = computed(() => `width:${props.size}px;height:${props.size}px`);
const svgViewBox = computed(() => `0 0 ${props.size} ${props.size}`);
</script>
<template>
  <svg
    :style="svgStyle"
    :viewBox="svgViewBox"
  >
    <path
      :d="pathData"
      v-bind="pathAttrs"
    />
  </svg>
</template>
