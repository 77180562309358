<template>
  <DialogForm
    :default-payload="defaultLog"
    :external-errors="externalErrors"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload }">
      <RowForm>
        <ColBase>
          <ControlSelectSingle
            v-model="payload.severity"
            :options="severities"
            rules="required"
            field="severity"
            label="severity"
            :disabled="editDisabled"
          />
        </ColBase>
      </RowForm>
      <RowForm>
        <ColBase>
          <ControlTextarea
            v-model="payload.message"
            field="message"
            rules="required|min:3|max:255"
            label="Message"
            :disabled="editDisabled"
          />
        </ColBase>
      </RowForm>
      <RowForm v-if="!hideMeta">
        <ColBase>
          <LabelBase text="meta" />
          <ListBase
            :items="convertMetaToItems(payload.meta)"
            field="meta"
            :fields="metaFields"
            primary-key="field"
            :initial-sort="metaSort"
            :items-per-page="100"
          />
        </ColBase>
      </RowForm>
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </DialogForm>
</template>

<script>
import { ColBase, ControlSelectSingle, ControlTextarea, DialogForm, LabelBase, ListBase, RowForm, SYSLOG_SEVERITIES } from "@/lib";

export default {
  components: { ColBase, ControlSelectSingle, ControlTextarea, DialogForm, LabelBase, ListBase, RowForm },
  data() {
    const severities = Object
      .entries(SYSLOG_SEVERITIES)
      .map(([text, value]) => ({ text, value }));

    const metaFields = [
      { key: "field", label: "Campo" },
      { key: "value", label: "Valor" },
    ];

    const metaSort = { direction: "asc", field: "field" };

    return {
      metaFields,
      metaSort,
      severities,
    };
  },
  methods: {
    convertMetaToItems(meta) {
      return Object.entries(meta).map(([field, value]) => ({ field, value }));
    },
  },
  name: "LogForm",
  props: {
    defaultLog: {
      default: () => ({}),
      type: Object,
    },
    editDisabled: {
      default: false,
      type: Boolean,
    },
    externalErrors: {
      default: null,
      type: [Object, Error],
    },
    hideMeta: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
