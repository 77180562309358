<template>
  <DialogForm
    :default-payload="defaultFluxo"
    :external-errors="externalErrors"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload }">
      <TabsAccordion
        v-slot="{ accordion }"
        field="fluxo"
      >
        <FlowFormBasicTab
          :accordion="accordion"
          :disabled="editDisabled"
          :fluxo="payload"
          :show="initialTab === 'basicos'"
        />
        <FlowFormStakeholdersTab
          :accordion="accordion"
          :hidden="hideDetails"
          :disabled="editDisabled"
          :fluxo="payload"
          :show="initialTab === 'interessados'"
        />
        <FlowFormDocumentsTab
          :accordion="accordion"
          :hidden="hideDetails"
          :disabled="editDisabled"
          :fluxo="payload"
        />
      </TabsAccordion>
    </template>
    <template #actions="{ payload }">
      <slot
        name="actions"
        :payload="payload"
      />
    </template>
  </DialogForm>
</template>

<script>
import { DialogForm, TabsAccordion } from "@/lib";

import FlowFormBasicTab from "./flow-form-basic-tab";
import FlowFormDocumentsTab from "./flow-form-documents-tab";
import FlowFormStakeholdersTab from "./flow-form-stakeholders-tab";

export default {
  components: {
    DialogForm,
    FlowFormBasicTab,
    FlowFormDocumentsTab,
    FlowFormStakeholdersTab,
    TabsAccordion,
  },
  name: "FlowForm",
  props: {
    defaultFluxo: {
      default: () => ({
        dadosBasicos: {},
        documentos: [],
        interessados: [],
      }),
      type: Object,
    },
    editDisabled: {
      default: false,
      type: Boolean,
    },
    externalErrors: {
      default: null,
      type: Object,
    },
    hideDetails: {
      default: false,
      type: Boolean,
    },
    initialTab: {
      default: "basicos",
      type: String,
    },
  },
};
</script>
