var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.FragmentBase,
    [
      _c(_setup.ButtonSecondary, {
        attrs: { field: "sign-out", label: "Sair" },
        on: {
          click: function ($event) {
            return _vm.$emit("signOut")
          },
        },
      }),
      _vm._v(" "),
      _c(_setup.ButtonPrimary, {
        attrs: { field: "fluxos", label: "Listar Fluxos" },
        on: {
          click: function ($event) {
            return _vm.$emit("list")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }