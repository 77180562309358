var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TabAccordion",
    _vm._b(
      {
        attrs: {
          field: "dados",
          "header-title": "Detalhes",
          "header-subtitle": _vm.fluxo.dadosBasicos.status,
          "header-icon-name": "data",
        },
      },
      "TabAccordion",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlInput", {
                attrs: {
                  field: "nome",
                  disabled: _vm.disabled,
                  rules: "required|min:3|max:100",
                  label: "Nome",
                },
                model: {
                  value: _vm.fluxo.dadosBasicos.nome,
                  callback: function ($$v) {
                    _vm.$set(_vm.fluxo.dadosBasicos, "nome", $$v)
                  },
                  expression: "fluxo.dadosBasicos.nome",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ColBase",
            { attrs: { sm: "" } },
            [
              _c("FlowFormBasicTabControlGroup", {
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.fluxo.dadosBasicos.idGrupo,
                  callback: function ($$v) {
                    _vm.$set(_vm.fluxo.dadosBasicos, "idGrupo", $$v)
                  },
                  expression: "fluxo.dadosBasicos.idGrupo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("FlowFormBasicTabControlRequester", {
                attrs: {
                  "id-requisitante": _vm.fluxo.dadosBasicos.idRequisitante,
                  hidden: _vm.hidden,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlTextarea", {
                attrs: {
                  field: "descricao",
                  disabled: _vm.disabled,
                  rules: "required|min:3|max:1000",
                  label: "Descrição",
                },
                model: {
                  value: _vm.fluxo.dadosBasicos.descricao,
                  callback: function ($$v) {
                    _vm.$set(_vm.fluxo.dadosBasicos, "descricao", $$v)
                  },
                  expression: "fluxo.dadosBasicos.descricao",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlCheckbox", {
                attrs: {
                  field: "icp",
                  disabled: _vm.disabled,
                  placeholder: "Requer uso de certificado ICP-Brasil.",
                  "helper-text":
                    "O assinante deve possuir um certificado emitido por uma autoridade ICP-Brasil.",
                  switch: "",
                },
                model: {
                  value: _vm.fluxo.dadosBasicos.isIcp,
                  callback: function ($$v) {
                    _vm.$set(_vm.fluxo.dadosBasicos, "isIcp", $$v)
                  },
                  expression: "fluxo.dadosBasicos.isIcp",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("FlowFormBasicTabControlSequencial", {
        attrs: {
          "is-sequencial": _vm.fluxo.dadosBasicos.isSequencial,
          "is-interrompe-se-negativo":
            _vm.fluxo.dadosBasicos.isInterrompeSeNegativo,
          interessados: _vm.fluxo.interessados,
          disabled: _vm.disabled,
        },
        on: {
          "update:isSequencial": function ($event) {
            return _vm.$set(_vm.fluxo.dadosBasicos, "isSequencial", $event)
          },
          "update:is-sequencial": function ($event) {
            return _vm.$set(_vm.fluxo.dadosBasicos, "isSequencial", $event)
          },
          "update:isInterrompeSeNegativo": function ($event) {
            return _vm.$set(
              _vm.fluxo.dadosBasicos,
              "isInterrompeSeNegativo",
              $event
            )
          },
          "update:is-interrompe-se-negativo": function ($event) {
            return _vm.$set(
              _vm.fluxo.dadosBasicos,
              "isInterrompeSeNegativo",
              $event
            )
          },
          "update:interessados": function ($event) {
            return _vm.$set(_vm.fluxo, "interessados", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }