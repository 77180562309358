<template>
  <!--
    prop disabled em BFormTextarea é utilizada para representar estado de
    somente leitura. a propriedade plaintext tb permite esse comportamento mas
    não preserva â mesma aparência que InputBase.
  -->
  <BFormTextarea
    v-if="!hide"
    :rows="rows"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { BFormTextarea } from "../bv";

export default {
  components: { BFormTextarea },
  name: "InputTextarea",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    rows: {
      default: "3",
      type: String,
    },
  },
};
</script>
