import { createEnvironment } from "@/lib";

import { resolveStage } from "./stage";

export function initEnvironment({ name, version }) {
  return createEnvironment({
    ...window.environmentVariables,
    stage: resolveStage(),
    system: name,
    version: version,
  });
}
