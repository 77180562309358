var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            [
              _c("ControlCheckbox", {
                attrs: {
                  field: "sequencial",
                  disabled: _vm.disabled,
                  placeholder: "Requer tratamento sequencial.",
                  "helper-text":
                    "As assinaturas devem seguir a ordem definida na lista de interessados.",
                  switch: "",
                },
                model: {
                  value: _vm.isSequencialInner,
                  callback: function ($$v) {
                    _vm.isSequencialInner = $$v
                  },
                  expression: "isSequencialInner",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            { attrs: { "offset-md": "1" } },
            [
              _vm.isSequencialInner
                ? _c("ControlCheckbox", {
                    attrs: {
                      field: "interrompe",
                      disabled: _vm.disabled,
                      placeholder:
                        "Finalizar fluxo após primeiro tratamento negativo.",
                      "helper-text":
                        "O fluxo é finalizado após uma rejeição de assinatura ou revisão com parecer negativo.",
                      switch: "",
                    },
                    model: {
                      value: _vm.isInterrompeSeNegativoInner,
                      callback: function ($$v) {
                        _vm.isInterrompeSeNegativoInner = $$v
                      },
                      expression: "isInterrompeSeNegativoInner",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }