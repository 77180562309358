import { ROUTING_ACCESSES, listCrumbsFlowsManagement } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-management-list-page.vue"
  );

export function createFlowsManagementListRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.REQUESTER],
      crumbs: listCrumbsFlowsManagement(),
      layout: "LayoutNav",
    },
    path: "/flows-management/list",
  };
}
