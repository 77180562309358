import { PROFILES } from "@/data";

export const ROUTING_ACCESSES = {
  NONE: "NONE",
  PUBLIC: "PUBLIC",
  ...PROFILES,
};

export function listSignedInRoutingAccesses() {
  return [
    ROUTING_ACCESSES.ADMINISTRATOR,
    ROUTING_ACCESSES.MANAGER,
    ROUTING_ACCESSES.STAKEHOLDER,
    ROUTING_ACCESSES.REQUESTER,
  ];
}

export function isAccessible(target, allowed) {
  if (allowed.includes(ROUTING_ACCESSES.PUBLIC)) return true;
  return allowed.includes(target.toUpperCase());
}
