import { createCollectionStoreModule } from "@/lib";

import { parseInteressadoItemToRecord, parseInteressadoRecordToItem } from "../interessados-helpers";

export function createInteressadosStore({ api, pageLimit, pollingSeconds }) {
  const baseEndpoint = "/v1/contas/perfil/INTERESSADO";

  return createCollectionStoreModule({
    api,
    editEndpoint: baseEndpoint,
    newEndpoint: baseEndpoint,
    pageLimit,
    parseItemToRecord: parseInteressadoItemToRecord,
    parseRecordToItem: parseInteressadoRecordToItem,
    pollingSeconds,
    queryEndpoint: baseEndpoint,
  });
}
