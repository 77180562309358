import { createAuthStore, createChangelogStore, createClientesStore, createContasStore, createFluxosAdministrativoStore, createFluxosArchivedStore, createFluxosExternoStore, createGestoresStore, createGruposStore, createInteressadosStore, createRequisitantesStore } from "@/data";
import { turnOffModules, turnOnModules } from "@/lib";
import Vue from "vue";
import Vuex from "vuex";

export async function initStore({ api, environment }) {
  Vue.use(Vuex);

  const otpBlockedSeconds = environment.get("WEB_OTP_BLOCKED_SECONDS", 60);
  const pageLimit = environment.get("WEB_PAGINATION_LIMIT", 100);
  const pollingSeconds = environment.get("WEB_POLLING_SECONDS", 3600);

  const vuexStore = new Vuex.Store({
    modules: {
      auth: createAuthStore({ api, otpBlockedSeconds }),
      changelog: createChangelogStore(),
      clientes: createClientesStore({ api, pageLimit, pollingSeconds }),
      contas: createContasStore({ api }),
      fluxosAdministrativo: createFluxosAdministrativoStore({ api, pageLimit, pollingSeconds }),
      fluxosArchived: createFluxosArchivedStore({ api, pageLimit, pollingSeconds }),
      fluxosExterno: createFluxosExternoStore({ api, pageLimit, pollingSeconds }),
      gestores: createGestoresStore({ api, pageLimit, pollingSeconds }),
      grupos: createGruposStore({ api, pageLimit, pollingSeconds }),
      interessados: createInteressadosStore({ api, pageLimit, pollingSeconds }),
      requisitantes: createRequisitantesStore({ api, pageLimit, pollingSeconds }),
    },
    strict: environment.is("WEB_MODE", { expected: "production", ignoreCase: true }),
  });

  const getUserPerfil = (state, getters) => getters["auth/perfilIfSignedIn"];
  vuexStore.watch(getUserPerfil, userPerfil => restartModules(userPerfil, vuexStore));

  return vuexStore;
}

// inicialmente foi utilizado o recurso de cadastrar e descadastrar módulos dinamicamente da vuex store. entretanto, essa abordagem não funcionou. os tracks de reatividade do vue (computed, watch...) não conseguiam reagir a mudanças nos getters dos módulos dinâmicos em algumas situações. daí foi implementado o design de se cadastrar todos os módulos desde o inicio e depois adotar o conceito de "on" e "off" para lidar com limpeza de dados e controle de comportamentos como polling.
function restartModules(userPerfil, store) {
  // por padrão apenas as collectionStore precisam dessa gestão de restart porque elas dependem de um turnOn para ativar o querying e polling de dados do backend.
  const allModulesThatNeedRestart = [
    "clientes",
    "fluxosAdministrativo",
    "fluxosArchived",
    "fluxosExterno",
    "gestores",
    "grupos",
    "interessados",
    "requisitantes",
  ];

  turnOffModules(store, allModulesThatNeedRestart);

  const perfisModules = {
    ADMINISTRADOR: [
      "clientes",
      "grupos",
      "gestores",
      "requisitantes",
      "interessados",
    ],
    GESTOR: [
      "grupos",
      "gestores",
      "requisitantes",
      "interessados",
    ],
    INTERESSADO: [
      "fluxosExterno",
    ],
    REQUISITANTE: [
      // a ordem dos módulos influencia no inicio do comportamento de querying de dados portanto manter essa ordem preservando a capacidade de consultar e criar fluxos
      "fluxosAdministrativo",
      "grupos",
      "gestores",
      "requisitantes",
      "interessados",
      "fluxosArchived",
    ],
  };

  const perfilModules = perfisModules[userPerfil] || [];
  turnOnModules(store, perfilModules);
}
