<template>
  <ButtonIcon
    :field="camelCase('del', 'interessado', field)"
    :hide="hide"
    icon="del"
    tooltip="desvincular interessado"
    @click="handleUnlink"
  />
</template>

<script>
import { ButtonIcon, camelCase } from "@/lib";

export default {
  components: { ButtonIcon },
  methods: {
    camelCase,
    handleUnlink() {
      const newInteressados = this.interessados.reduce((acc, cur) => {
        if (cur.codigo === this.interessado.codigo) return acc;

        const ordem = this.isSequencial ? acc.length + 1 : 0;
        acc.push({
          ...cur,
          ordem,
        });
        return acc;
      }, []);
      this.$emit("update:interessados", newInteressados);
    },
  },
  name: "FlowFormStakeholdersTabManagerButtonDetach",
  props: {
    field: {
      required: true,
      type: [String, Number],
    },
    hide: {
      default: false,
      type: Boolean,
    },
    interessado: {
      default: () => ({}),
      type: Object,
    },
    interessados: {
      default: () => [],
      type: Array,
    },
    isSequencial: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
