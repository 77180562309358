import { createLog } from "./log.js";
import { enablePersistence } from "./persistence";

export function createLogger({ effects: userEffects = [], meta: defaultMeta = {}, size = 10, storageKey = "LOGGER" } = {}) {
  const { clear, list, save } = enablePersistence({ size, storageKey });

  const effects = [save, ...userEffects];
  const log = createLog({ effects, meta: defaultMeta });

  const logger = { clear, list, log };

  return logger;
}
