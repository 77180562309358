export function triggerBrowserDownload({ blob, name }) {
  // um link com href não garante o download. em alguns casos promove a abertura do documento no próprio navegador e o uso do atributo 'download' não funciona quando o href tem origem diferente do site. a solução abaixo é baseada em: https://stackoverflow.com/questions/49668363/html5-download-attribute-not-working-when-download-external-pdf-file-on-chrome
  const anchor = document.createElement("a");
  anchor.style.display = "none";
  anchor.href = window.URL.createObjectURL(blob);
  anchor.setAttribute("download", name);
  document.body.appendChild(anchor);

  anchor.click();

  window.URL.revokeObjectURL(anchor.href);
  document.body.removeChild(anchor);
}
