import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

import { download } from "./download";
import { prepareUplodad } from "./upload";

export function createRequest(client) {
  return async (config, payload) => {
    const { endpoint, method = "get", ...options } = config;
    let response;
    try {
      if (method === "upload") {
        const { formData, headers } = prepareUplodad(payload);
        response = await client({ data: formData, headers, method: "post", url: endpoint });
      }
      else if (method === "download") {
        response = await download(endpoint);
      }
      else {
        response = await client({ data: payload, method, url: endpoint, ...options });
      }

      if (response.status >= 300) throw new Error("force catch clause");
    }
    catch {
      throwBadRequest({ ...response, endpoint, method });
    }

    const data = parseResponseData(response);
    return data;
  };
}

function throwBadRequest(options = {}) {
  const { data = {}, endpoint, method, status, statusText } = options;
  const code = data.code || status;

  // statusText também poderia ser considerado como alternativa de mensagem mas foi abandonado por ser normalmente um texto técnico em inglês
  const message = data.message || "Aconteceu um erro inesperado na aplicação durante a requisição.";

  const severity = status < 500 ? SYSLOG_SEVERITIES.WARNING : SYSLOG_SEVERITIES.ERROR;
  const meta = {
    ...data,
    endpoint,
    isHttp: true,
    method,
    status,
    statusText,
  };
  AppError.throw({ code, message, meta, severity });
}

function parseResponseData(response) {
  const { data } = response;

  // algumas respostas emitidas através do krakenD gateway tem formato específico. o código abaixo é uma conversão desse formato para o que é convencionado no módulo web
  if (Array.isArray(data?.collection)) return data.collection;

  return data;
}
