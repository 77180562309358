<template>
  <BFormTags
    v-if="!hide"
    :id="id"
    :state="state"
    :value="selectedOptionsValues"
    :disabled="disabled"
    remove-on-delete
    add-on-change
    no-outer-focus
    tag-variant="primary"
    class="select-multi-base pt-3 pb-1 pl-3 pr-6"
    v-on="$listeners"
  >
    <template
      #default="{
        tags,
        inputAttrs,
        inputHandlers,
        disabled: selectDisabled,
        removeTag,
      }"
    >
      <BFormSelect
        :options="unselectedOptions"
        :text-field="textField"
        :value-field="valueField"
        :disabled="selectDisabled || unselectedOptions.length === 0"
        v-bind="inputAttrs"
        v-on="inputHandlers"
      />
      <p
        v-if="tags.length === 0"
        class="mt-2"
      >
        {{ placeholder }}
      </p>
      <ul
        v-else
        class="list-inline d-inline-block mt-2"
      >
        <li
          v-for="tag in tags"
          :key="tag"
          class="list-inline-item"
        >
          <BFormTag
            :title="tag"
            variant="secondary"
            pill
            :disabled="selectDisabled || isTagDisabled(tag)"
            @remove="removeTag(tag)"
          >
            {{ tagText(tag) }}
          </BFormTag>
        </li>
      </ul>
    </template>
  </BFormTags>
</template>

<script>
import { asArray, sortByField } from "@/lib/arrays";

import { BFormSelect, BFormTag, BFormTags } from "../bv";

export default {
  components: { BFormSelect, BFormTag, BFormTags },
  computed: {
    hasSelection() {
      return asArray(this.value).length > 0;
    },
    selectedOptionsValues() {
      return this.sortedOptions
        .filter(opt => asArray(this.value).includes(opt[this.valueField]))
        .map(opt => opt[this.valueField]);
    },
    sortedOptions() {
      return sortByField(this.options, { field: this.textField });
    },
    unselectedOptions() {
      return this.sortedOptions.filter(opt => !asArray(this.value).includes(opt[this.valueField]));
    },
  },
  methods: {
    isTagDisabled(tag) {
      const option = this.options.find(o => o[this.valueField] === tag);
      if (!option) return false;

      return option.disabled;
    },
    tagText(tag) {
      const option = this.options.find(o => o[this.valueField] === tag);
      if (!option || !option[this.textField]) return "";

      return option[this.textField];
    },
  },
  name: "SelectMultiBase",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hide: {
      default: false,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    placeholder: {
      default: null,
      type: String,
    },
    rows: {
      default: 3,
      type: Number,
    },
    state: {
      default: null,
      type: Boolean,
    },
    textField: {
      required: true,
      type: String,
    },
    value: {
      default: () => [],
      type: Array,
    },
    valueField: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.select-multi-base select {
  border-color: var(--w-color-neutral-20);
}
</style>
