<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ passed, errors, valid }"
    :rules="rules"
    :name="label ? label.toUpperCase() : null"
    :vid="camelCase('validation', field)"
    mode="eager"
  >
    <slot
      :state="deriveState({ rules, errors, passed })"
      :error="errors[0]"
      :valid="valid"
      :provider="$refs.provider"
    />
  </ValidationProvider>
</template>

<script>
import { camelCase } from "@/lib/strings";
import { ValidationProvider } from "vee-validate";

export default {
  components: { ValidationProvider },
  methods: {
    camelCase,
    deriveState({ errors, passed, rules }) {
      /*
        O atributo "state" dos controles do BootstrapVue pode ser true, false ou null e  cada um dos valores tem um estilo visual correspondente:
        - null: não há erro e não é válido, a validação ainda não teve chance de ser executada ou não deve ser feita no controle. o controle é renderizado de forma neutra.
        - true: a validação foi executada com sucesso e renderiza com success.
        - false: há erro e renderiza com danger.
      */
      if (!rules) return null;
      if (errors.length > 0) return false;
      return passed ? true : null;
    },
  },
  mounted() {
    /*
      não foi possível usar a opção tradicional the watch para observar a referência ao
      provider. a alternativa é utilizar o método $watch após a montagem do componente.
      mais aqui:
      https://stackoverflow.com/questions/51225378/how-to-watch-child-properties-changes-from-parent-component
    */
    this.$watch("$refs.provider.flags.valid", function (value) {
      const payload = {
        isValid: value,
        value: this.$refs.provider.value,
      };
      this.$emit("validation", payload);
    });
  },
  name: "ValidationWrapper",
  props: {
    field: {
      required: true,
      type: String,
    },
    label: {
      default: null,
      type: String,
    },
    rules: {
      default: null,
      type: [String, Object],
    },
  },
};
</script>
