import { SYSLOG_SEVERITIES, consoleTrigger, createHttp } from "@/lib";

export function createEffects(webLoggerUrl) {
  const consoleEffect = { severity: SYSLOG_SEVERITIES.DEBUG, trigger: consoleTrigger };
  const effects = [consoleEffect];

  if (webLoggerUrl) {
    const webLoggerEffect = { severity: SYSLOG_SEVERITIES.NOTICE, trigger: createWebLoggerTrigger(webLoggerUrl) };
    effects.push(webLoggerEffect);
  }

  return effects;
}

function createWebLoggerTrigger(webLoggerUrl) {
  const http = createHttp({ baseUrl: webLoggerUrl });
  return log => http.request({ endpoint: "/", method: "post" }, log);
}
