<template>
  <FragmentBase>
    <RowForm>
      <ColBase>
        <ControlCheckbox
          v-model="isSequencialInner"
          field="sequencial"
          :disabled="disabled"
          placeholder="Requer tratamento sequencial."
          helper-text="As assinaturas devem seguir a ordem definida na lista de interessados."
          switch
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase offset-md="1">
        <ControlCheckbox
          v-if="isSequencialInner"
          v-model="isInterrompeSeNegativoInner"
          field="interrompe"
          :disabled="disabled"
          placeholder="Finalizar fluxo após primeiro tratamento negativo."
          helper-text="O fluxo é finalizado após uma rejeição de assinatura ou revisão com parecer negativo."
          switch
        />
      </ColBase>
    </RowForm>
  </FragmentBase>
</template>

<script>
import { ColBase, ControlCheckbox, FragmentBase, RowForm } from "@/lib";

export default {
  components: { ColBase, ControlCheckbox, FragmentBase, RowForm },
  data() {
    return {
      isInterrompeSeNegativoInner: this.isInterrompeSeNegativo,
      isSequencialInner: this.isSequencial,
    };
  },
  methods: {
    checkIfOrderIsValid(interessados) {
      const ordens = interessados.map(({ ordem }) => ordem);

      const ordemSet = new Set(ordens);
      if (ordemSet.size !== ordens.length) return false;

      ordens.sort((a, b) => a - b);
      const isSequential = ordens.every((value, index) => value === index + 1);
      return isSequential;
    },
    updateInteressados(isSequencial) {
      const interessadosHaveValidOrder = this.checkIfOrderIsValid(this.interessados);
      const newInteressados = this.interessados.map((interessado, i) => ({
        ...interessado,
        ordem: !isSequencial ? 0 : interessadosHaveValidOrder ? interessado.ordem : i + 1,
      }));
      this.$emit("update:interessados", newInteressados);
    },
    updateIsInterrompeSeNegativo(newValue) {
      this.$emit("update:isInterrompeSeNegativo", newValue);
    },
    updateIsSequencial(newValue) {
      this.$emit("update:isSequencial", newValue);
    },
  },
  name: "FlowFormBasicTabControlSequencial",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    interessados: {
      default: () => [],
      type: Array,
    },
    isInterrompeSeNegativo: {
      default: false,
      type: Boolean,
    },
    isSequencial: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    isInterrompeSeNegativo(newValue) {
      this.isInterrompeSeNegativoInner = newValue;
    },
    isInterrompeSeNegativoInner: "updateIsInterrompeSeNegativo",
    isSequencial(newValue) {
      this.isSequencialInner = newValue;
    },
    isSequencialInner: ["updateIsSequencial", "updateInteressados"],
  },
};
</script>
