import { convertFromString } from "@/lib/strings";

/**
 * Retrieves an environment value from a data object and attempt to convert it to the data underline type.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.data - The data object containing environment values.
 * @param {string} params.name - The name of the environment variable to retrieve.
 * @param {*} [params.value=null] - The default value to return if the environment variable is not found.
 * @returns {*} The converted environment value or the default value if not found.
 */
export function getEnvironmentValue({ data, name, value = null }) {
  const getterOrCurrent = data[name];
  const current = typeof getterOrCurrent === "function" ? getterOrCurrent() : getterOrCurrent;
  return convertFromString(current, value);
}
