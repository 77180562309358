import { ROUTING_ACCESSES, listCrumbsFlowsEngagement } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-engagement-approvals-page.vue"
  );

export function createFlowsEngagementApprovalsRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.STAKEHOLDER],
      crumbs: [...listCrumbsFlowsEngagement(), { text: "Situação das Respostas" }],
      layout: "LayoutNav",
    },
    path: "/flows-engagement/approvals/:itemId",
    props: true,
  };
}
