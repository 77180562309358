<template>
  <TabAccordion
    field="documentos"
    :hidden="hidden"
    header-title="Documentos"
    header-icon-name="files"
    :header-icon-badge="howManyDocumentos"
    v-bind="$attrs"
  >
    <FlowFormDocumentsTabManager
      v-if="!hidden"
      field="fluxo"
      :fluxo="fluxo"
      :disabled="disabled"
    />
  </TabAccordion>
</template>

<script>
import { extractDocumentos } from "@/data";
import { TabAccordion } from "@/lib";

import FlowFormDocumentsTabManager from "./flow-form-documents-tab-manager";

export default {
  components: {
    FlowFormDocumentsTabManager,
    TabAccordion,
  },
  computed: {
    howManyDocumentos() {
      const documentos = extractDocumentos(this.fluxo);
      return documentos.length > 0 ? documentos.length : null;
    },
  },
  name: "FlowFormDocumentsTab",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    fluxo: {
      default: null,
      type: Object,
    },
    hidden: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
