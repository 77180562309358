<template>
  <CardBase :header-text="headerText">
    <div :id="camelCase('list', 'page', field)">
      <PanelButtons>
        <slot name="listActions" />
      </PanelButtons>
      <ListPower
        :field="field"
        :items="items"
        :primary-key="primaryKey"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template #actions="{ item, index }">
          <slot
            name="listItemActions"
            :index="index"
            :item="item"
          />
        </template>
      </ListPower>
    </div>
  </CardBase>
</template>

<script>
import { camelCase } from "@/lib/strings";

import { PanelButtons } from "../buttons";
import { CardBase } from "../dialogs";
import { ListPower } from "../lists";

export default {
  components: { CardBase, ListPower, PanelButtons },
  methods: {
    camelCase,
  },
  name: "ListPage",
  props: {
    field: {
      required: true,
      type: String,
    },
    headerText: {
      required: true,
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
    primaryKey: {
      default: "id",
      type: String,
    },
  },
};
</script>
