var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ManagerSession"),
      _vm._v(" "),
      _c(_vm.layout, { tag: "component" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }