import { ROUTING_ACCESSES, listCrumbsClients } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./clients-add-page.vue"
  );

export function createClientsAddRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR],
      crumbs: [...listCrumbsClients(), { text: "Inclusão de Cliente" }],
      layout: "LayoutNav",
    },
    path: "/clients/add",
  };
}
