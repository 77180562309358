<script setup>
import { computed } from "vue";

import ButtonBase from "./button-base";

const props = defineProps({
  bgColor: {
    default: "transparent",
    type: String,
  },
  iconSize: {
    default: 24,
    type: Number,
  },
  textColor: {
    default: "var(--w-color-neutral-50)",
    type: String,
  },
});

const cssVars = computed(() => ({ "--bg-color": props.bgColor, "--text-color": props.textColor }));
</script>

<template>
  <ButtonBase
    size="sm"
    class="action-icon py-1 px-2"
    :style="cssVars"
    :icon-size="iconSize"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<style scoped>
.action-icon {
  color: var(--text-color);
  background-color: var(--bg-color);
  filter: none;
  border: none;
}

.action-icon:focus {
  color: var(--text-color);
  background-color: var(--w-color-neutral-10);
  box-shadow: var(--w-shadow-primary);
}

.action-icon:hover {
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  box-shadow: var(--w-shadow-primary);
}

.action-icon:active {
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  filter: none;
}

.action-icon:disabled {
  color: var(--w-color-neutral-40);
  background-color: var(--bg-color);
  filter: none;
  pointer-events: none;
}
</style>
