<template>
  <ButtonTertiary
    field="discard"
    label="Descartar"
    @click="confirm()"
  />
</template>

<script>
import { ButtonTertiary } from "@/lib";

export default {
  components: {
    ButtonTertiary,
  },
  methods: {
    async confirm() {
      const message = "Todos os dados não salvos serão perdidos. Deseja descartar as alterações mesmo assim?";
      const hasConfirmed = await this.$showAlert(message, {
        cancelLabel: "Não",
        confirmLabel: "Sim",
        enableCancel: true,
      });
      if (hasConfirmed) {
        // não é possível realizar um router.back aqui. se o usuário estiver em um workflow em que adicionou um fluxo e avançou para edição. dai na tela de edição decidiu descartar alteração. ao clicar em descartar, o sistema deve ir para a listagem e não voltar para a tela de inclusão.
        this.$router.push("/flows-management/list");
      }
    },
  },
  name: "FlowManagementDiscardButton",
};
</script>
