import { isObjectLike } from "./is-object-like";

export function isObjectFull(value, { emptyPropsAreConsiderFull = false } = {}) {
  if (!isObjectLike(value)) return false;
  if (Object.keys(value).length === 0) return false;

  // neste caso bastou saber que há propriedades com qualquer valor para considerar o objeto como preenchido
  if (emptyPropsAreConsiderFull) return true;

  for (const property in value) {
    const propertyValue = value[property];
    // se a prop for um objeto preenchido  então esse tratar o objeto agregador como preenchido tb
    if (isObjectFull(propertyValue)) return true;

    // se não for um objeto e carregar valor, então o objeto agregador pode ser considerado como preenchido
    if (!isObjectLike(propertyValue) && propertyValue !== null && propertyValue !== undefined) return true;
  }

  return false;
}
