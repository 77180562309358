var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DialogForm", {
    attrs: {
      "default-payload": _vm.defaultLog,
      "external-errors": _vm.externalErrors,
      "show-action-divider": "",
    },
    on: {
      save: function ($event) {
        return _vm.$emit("save", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ payload }) {
            return [
              _c(
                "RowForm",
                [
                  _c(
                    "ColBase",
                    [
                      _c("ControlSelectSingle", {
                        attrs: {
                          options: _vm.severities,
                          rules: "required",
                          field: "severity",
                          label: "severity",
                          disabled: _vm.editDisabled,
                        },
                        model: {
                          value: payload.severity,
                          callback: function ($$v) {
                            _vm.$set(payload, "severity", $$v)
                          },
                          expression: "payload.severity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "RowForm",
                [
                  _c(
                    "ColBase",
                    [
                      _c("ControlTextarea", {
                        attrs: {
                          field: "message",
                          rules: "required|min:3|max:255",
                          label: "Message",
                          disabled: _vm.editDisabled,
                        },
                        model: {
                          value: payload.message,
                          callback: function ($$v) {
                            _vm.$set(payload, "message", $$v)
                          },
                          expression: "payload.message",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.hideMeta
                ? _c(
                    "RowForm",
                    [
                      _c(
                        "ColBase",
                        [
                          _c("LabelBase", { attrs: { text: "meta" } }),
                          _vm._v(" "),
                          _c("ListBase", {
                            attrs: {
                              items: _vm.convertMetaToItems(payload.meta),
                              field: "meta",
                              fields: _vm.metaFields,
                              "primary-key": "field",
                              "initial-sort": _vm.metaSort,
                              "items-per-page": 100,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [_vm._t("actions")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }