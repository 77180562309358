import { clone, createConnectedStoreModule } from "@/lib";

import {
  actions as linkAutenticadoActions,
  getters as linkAutenticadoGetters,
  initialState as linkAutenticadoInitialState,
  mutations as linkAutenticadoMutations,
  stateKey as linkAutenticadoStateKey,
} from "./link-autenticado";
import {
  actions as otpActions,
  createInitialState as otpCreateInitialState,
  getters as otpGetters,
  mutations as otpMutations,
  stateKey as otpStateKey,
} from "./otp";
import {
  actions as profileActions,
  getters as profileGetters,
  initialState as profileInitialState,
  mutations as profileMutations,
  stateKey as profileStateKey,
} from "./profile";
import {
  actions as signActions,
  getters as signGetters,
  initialState as signInitialState,
  mutations as signMutations,
  stateKey as signStateKey,
} from "./sign";
import {
  actions as storageActions,
} from "./storage";
import {
  actions as webAuthnActions,
  getters as webAuthnGetters,
  initialState as webAuthnInitialState,
  mutations as webAuthnMutations,
  stateKey as webAuthnStateKey,
} from "./web-authn";

export function createAuthStore({ api, otpBlockedSeconds }) {
  const initialState = {
    [linkAutenticadoStateKey]: linkAutenticadoInitialState,
    [otpStateKey]: otpCreateInitialState(otpBlockedSeconds),
    [profileStateKey]: profileInitialState,
    [signStateKey]: signInitialState,
    [webAuthnStateKey]: webAuthnInitialState,
  };

  return createConnectedStoreModule({
    actions: {
      ...linkAutenticadoActions,
      ...otpActions,
      ...profileActions,
      ...signActions,
      ...storageActions,
      ...webAuthnActions,
    },
    api,
    getters: {
      ...linkAutenticadoGetters,
      ...otpGetters,
      ...profileGetters,
      ...webAuthnGetters,
      ...signGetters,
    },
    mutations: {
      resetState(state, newState = initialState) {
        Object.assign(state, clone(newState));
      },
      ...linkAutenticadoMutations,
      ...otpMutations,
      ...profileMutations,
      ...webAuthnMutations,
      ...signMutations,
    },
    state: clone(initialState),
  });
}
