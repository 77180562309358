<template>
  <OverlayBase :is-busy="isBusy">
    <DialogForm
      ref="form"
      :external-errors="lastError"
      @save="execute(authenticate)"
    >
      <template #body>
        <div
          v-if="lastError"
          class="centered"
        >
          <DescriptionText>
            Algo deu errado com sua autenticação via link mas não se preocupe. O Neosigner tem outras formas de acesso.
          </DescriptionText>
          <ButtonPrimary
            label="Tentar outra forma de autenticação"
            field="back"
            @click="cancel"
          />
        </div>
        <DescriptionText
          v-else
          class="centered"
        >
          Por favor, aguarde enquanto tentamos te autenticar.
        </DescriptionText>
      </template>
    </DialogForm>
  </OverlayBase>
</template>

<script>
import { ButtonPrimary, DescriptionText, DialogForm, OverlayBase, useExecute } from "@/lib";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    ButtonPrimary,
    DescriptionText,
    DialogForm,
    OverlayBase,
  },
  data() {
    const { execute, isBusy, lastError } = useExecute();
    return { execute, isBusy, lastError };
  },
  methods: {
    ...mapActions("auth", ["authenticateWithLink"]),
    ...mapMutations("auth", ["resetState", "setWebAuthnRegistrationDone"]),
    async authenticate() {
      await this.authenticateWithLink();
      // a interface de autenticação via link arbitrariamente dispensa o registro de dispositivo webAuthn. a experiência desejada é iniciar diretamente na tela de assinatura
      this.setWebAuthnRegistrationDone();
    },
    cancel() {
      this.resetState();
      this.$router.push("/default");
    },
  },
  mounted() {
    this.$refs.form.onSubmit();
  },
  name: "LinkDialog",
};
</script>

<style scoped>
.centered {
  text-align: center;
}
</style>
