import { SYSLOG_SEVERITIES } from "@/lib/severities";

export const LOG_ERRORS = {
  EFFECT_NOT_FUNCTION: {
    code: "INVALID_CONFIGURATION/EFFECT_NOT_FUNCTION",
    message: "every logger effect must be a function or an object with a trigger property that is a function",
    severity: SYSLOG_SEVERITIES.EMERGENCY,
  },
  EFFECTS_NOT_ARRAY: {
    code: "INVALID_CONFIGURATION/EFFECTS_NOT_ARRAY",
    message: "logger effects configuration must be an array",
    severity: SYSLOG_SEVERITIES.EMERGENCY,
  },
};
