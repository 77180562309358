<template>
  <DescriptionText>
    Percebemos que você já possui um dispositivo cadastrado para se autenticar ao Neosigner. Vamos tentar utilizar ele.
  </DescriptionText>
</template>

<script>
import { DescriptionText } from "@/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    DescriptionText,
  },
  computed: {
    ...mapGetters("auth", [
      "idCliente",
      "channels",
      "channelOfType",
      "isWebAuthnAuthenticatorAvailable",
    ]),
  },
  methods: {
    ...mapActions("auth", ["authenticateWithWebAuthn"]),
    ...mapMutations("auth", ["resetState", "setWebAuthnSkippedAuthentication"]),
  },
  async mounted() {
    try {
      await this.authenticateWithWebAuthn();
    }
    catch {
      this.setWebAuthnSkippedAuthentication();
    }
  },
  name: "AuthenticationDialog",
};
</script>
