<template>
  <ButtonTertiary
    field="back"
    label="Voltar"
    @click="handleClick"
  />
</template>

<script>
import { ButtonTertiary } from "@/lib";

export default {
  components: { ButtonTertiary },
  methods: {
    handleClick() {
      if (!this.to) return this.$router.back();

      this.$router.push(this.to);
    },
  },
  name: "BackButton",
  props: {
    to: {
      default: null,
      type: String,
    },
  },
};
</script>
