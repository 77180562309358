<template>
  <ControlSelectSingle
    field="grupo"
    :options="requisitantegroupsWithInactiveLabel"
    value-field="id"
    text-field="nome"
    rules="required"
    label="Grupo"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { groupsWithInactiveLabel } from "@/data";
import { ControlSelectSingle } from "@/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    ControlSelectSingle,
  },
  computed: {
    ...mapGetters("requisitantes", ["currentRequisitanteGrupos"]),
    requisitantegroupsWithInactiveLabel() {
      return groupsWithInactiveLabel(this.currentRequisitanteGrupos);
    },
  },
  name: "FlowFormBasicTabControlGroup",
};
</script>
