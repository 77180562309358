var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.MenuText, [_vm._v("Ajuda")]),
      _vm._v(" "),
      _c(_setup.MenuItem, { attrs: { href: "/manual", target: "_blank" } }, [
        _vm._v("\n    Manual de utilização\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }