<template>
  <ButtonIcon
    icon="download"
    tooltip="download"
    :hide="hide || !downloadBlob"
    v-bind="$attrs"
    @click="handleDownload"
  />
</template>

<script>
import { triggerBrowserDownload } from "@/lib/download";

import { ButtonIcon } from "../buttons";

export default {
  components: { ButtonIcon },
  methods: {
    async handleDownload() {
      const blob = await this.downloadBlob();
      triggerBrowserDownload({ blob, name: this.fileName });
    },
  },
  name: "FileButtonDownload",
  props: {
    downloadBlob: {
      default: null,
      type: Function,
    },
    fileName: {
      default: "",
      type: String,
    },
    hide: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
