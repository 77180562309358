export function createRequestFeatures(config) {
  return {
    actions: {
      requestAction: createRequestAction(config),
    },
    getters: {
      hasRequestedOnce: state => state.hasRequestedOnce,
      isRequesting: state => state.pendingRequests > 0,
    },
    mutations: {
      startRequesting: (state) => {
        state.pendingRequests++;
      },
      stopRequesting: (state) => {
        if (state.pendingRequests > 0) {
          state.pendingRequests--;
        }
        if (!state.hasRequestedOnce) {
          state.hasRequestedOnce = true;
        }
      },
    },
    state: {
      hasRequestedOnce: false,
      pendingRequests: 0,
    },
  };
}

function createRequestAction(config) {
  const { api } = config;

  return async ({ commit }, payload) => {
    const { endpoint, headers, method, record } = payload;

    commit("startRequesting");
    let result;
    try {
      result = await api.request({ endpoint, headers, method }, record);
    }
    finally {
      commit("stopRequesting");
    }
    return result;
  };
}
