var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.ButtonBase,
    _vm._g(
      _vm._b(
        {
          staticClass: "action-icon py-1 px-2",
          style: _setup.cssVars,
          attrs: { size: "sm", "icon-size": _vm.iconSize },
        },
        "ButtonBase",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }