import { getEnvironmentValue } from "./get";

export function listEnvironmentValues(data) {
  return Object.keys(data).reduce((acc, name) => {
    acc.push({ name, value: getEnvironmentValue({ data, name }) });
    return acc;
  }, []);
}

export function hashEnvironmentValues(data) {
  return Object.keys(data).reduce((acc, name) => {
    acc[name] = getEnvironmentValue({ data, name });
    return acc;
  }, {});
}
