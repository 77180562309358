var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DialogForm", {
    attrs: {
      "default-payload": _vm.defaultInteressado,
      "external-errors": _vm.externalErrors,
      "external-errors-field-map": { codigo: "codigoInteressado" },
      "show-action-divider": "",
    },
    on: {
      save: function ($event) {
        return _vm.$emit("save", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ payload }) {
            return [
              _c("AccountDialog", {
                ref: "accountDialog",
                attrs: {
                  field: "interessado",
                  "is-edit-mode": _vm.isEditMode,
                  "tipo-codigo": payload.tipoCodigo,
                  codigo: payload.codigo,
                  nome: payload.nome,
                  email: payload.email,
                  celular: payload.celular,
                },
                on: {
                  "update:tipoCodigo": function ($event) {
                    return _vm.$set(payload, "tipoCodigo", $event)
                  },
                  "update:tipo-codigo": function ($event) {
                    return _vm.$set(payload, "tipoCodigo", $event)
                  },
                  "update:codigo": function ($event) {
                    return _vm.$set(payload, "codigo", $event)
                  },
                  "update:nome": function ($event) {
                    return _vm.$set(payload, "nome", $event)
                  },
                  "update:email": function ($event) {
                    return _vm.$set(payload, "email", $event)
                  },
                  "update:celular": function ($event) {
                    return _vm.$set(payload, "celular", $event)
                  },
                },
              }),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [
              _vm._t("actions", null, {
                clearAccountData: _vm.$refs.accountDialog.reset,
              }),
              _vm._v(" "),
              _c("ButtonsSaveBack", {
                on: {
                  back: function ($event) {
                    return _vm.$emit("back")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }