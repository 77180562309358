var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalBase", {
    ref: "modal",
    attrs: { size: "lg" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function ({ save, cancel }) {
          return [
            _c("DialogForm", {
              attrs: {
                "default-payload": _vm.defaultInteressado,
                "show-action-divider": "",
              },
              on: {
                save: function ($event) {
                  return save($event)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "body",
                    fn: function ({ payload }) {
                      return [
                        _c(
                          "RowForm",
                          [
                            _c(
                              "ColBase",
                              [
                                _c("ControlSelectSingle", {
                                  attrs: {
                                    field: "papel",
                                    options: ["ASSINANTE", "REVISOR"],
                                    rules: "required",
                                    label: "Papel",
                                  },
                                  model: {
                                    value: payload.papel,
                                    callback: function ($$v) {
                                      _vm.$set(payload, "papel", $$v)
                                    },
                                    expression: "payload.papel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("AccountDialog", {
                          ref: "AccountDialog",
                          attrs: {
                            field: "interessado",
                            "tipo-codigo": payload.tipoCodigo,
                            codigo: payload.codigo,
                            nome: payload.nome,
                            email: payload.email,
                            celular: payload.celular,
                          },
                          on: {
                            "update:tipoCodigo": function ($event) {
                              return _vm.$set(payload, "tipoCodigo", $event)
                            },
                            "update:tipo-codigo": function ($event) {
                              return _vm.$set(payload, "tipoCodigo", $event)
                            },
                            "update:codigo": function ($event) {
                              return _vm.$set(payload, "codigo", $event)
                            },
                            "update:nome": function ($event) {
                              return _vm.$set(payload, "nome", $event)
                            },
                            "update:email": function ($event) {
                              return _vm.$set(payload, "email", $event)
                            },
                            "update:celular": function ($event) {
                              return _vm.$set(payload, "celular", $event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c("ButtonTertiary", {
                          attrs: { field: "reset", label: "Limpar" },
                          on: { click: _vm.handleReset },
                        }),
                        _vm._v(" "),
                        _c("ButtonsSaveBack", {
                          on: {
                            back: function ($event) {
                              return cancel()
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }