export function createDefaultWebhook() {
  // esse formato flat com chaves lógicas simplifica a criação de formulários usando v-model porque não há objetos aninhados com possivel valor null e possibilita preservar valores digitados mesmo que o usuário desabilite alguma autenticação. o formato também simplifica a conversão dos valores com o formato esperado pelo backend.
  return {
    apikeyHas: false,
    apikeyName: null,
    apikeyValue: null,
    method: "POST",
    oauth2Client: null,
    oauth2Has: false,
    oauth2Scope: null,
    oauth2Secret: null,
    oauth2Type: "CLIENT_CREDENTIALS",
    oauth2Url: null,
    url: null,
  };
}
