import { ROUTING_ACCESSES, listCrumbsGroups } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./groups-list-page.vue"
  );

export function createGroupsListRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER],
      crumbs: listCrumbsGroups(),
      layout: "LayoutNav",
    },
    path: "/groups/list",
  };
}
