var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ListPower", {
    attrs: {
      field: "approvals",
      items: _vm.respostasForList,
      fields: _vm.fields,
      "initial-sort": _vm.initialSort,
      "search-config": _vm.searchConfig,
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({ item: resposta, index }) {
          return [
            _c("FeedbackToggle", {
              attrs: {
                field: index,
                resposta: resposta,
                "show-details": resposta._showDetails,
              },
              on: {
                "update:showDetails": function ($event) {
                  return _vm.$set(resposta, "_showDetails", $event)
                },
                "update:show-details": function ($event) {
                  return _vm.$set(resposta, "_showDetails", $event)
                },
              },
            }),
          ]
        },
      },
      {
        key: "row-details",
        fn: function ({ item, index }) {
          return [
            _c("InputRichText", {
              attrs: { id: _vm.camelCase("feedback", index), disabled: "" },
              model: {
                value: item.parecer,
                callback: function ($$v) {
                  _vm.$set(item, "parecer", $$v)
                },
                expression: "item.parecer",
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }