<template>
  <FragmentBase>
    <ButtonIcon
      :field="field"
      :hide="hide"
      :disabled="disabled"
      icon="view"
      tooltip="visualizar pdf"
      @click="handleViewPdf"
    />
    <PdfViewModal
      ref="modal"
      :file-path="filePath"
      :file-name="fileName"
      :download-blob="downloadBlob"
    />
  </FragmentBase>
</template>

<script>
import { ButtonIcon } from "../buttons";
import { FragmentBase } from "../layout";
import PdfViewModal from "./pdf-view-modal";

export default {
  components: {
    ButtonIcon,
    FragmentBase,
    PdfViewModal,
  },
  methods: {
    handleViewPdf() {
      this.$refs.modal.open();
    },
  },
  name: "PdfButtonView",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    downloadBlob: {
      required: true,
      type: Function,
    },
    field: {
      default: null,
      type: String,
    },
    fileName: {
      default: null,
      type: String,
    },
    filePath: {
      default: null,
      type: String,
    },
    hide: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
