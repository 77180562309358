import { listCrumbsLogs, listSignedInRoutingAccesses } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./logs-add-page.vue"
  );

export function createLogsAddRoute() {
  return {
    component,
    meta: {
      access: listSignedInRoutingAccesses(),
      crumbs: [...listCrumbsLogs(), { text: "Criação de log sintético" }],
      layout: "LayoutNav",
    },
    path: "/logs/add",
  };
}
