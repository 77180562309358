import axios from "axios";

import { createRequest } from "./request";
import { injectToken } from "./token";

export function createHttp({ baseUrl, tokenOrGetter } = {}) {
  const baseURL = baseUrl;

  const client = axios.create({
    baseURL,
    validateStatus: () => true,
  });

  if (tokenOrGetter) injectToken({ client, tokenOrGetter });

  const http = { client };
  http.request = createRequest(client);
  return http;
}
