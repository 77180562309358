import { getEnvironmentValue } from "./get";

/**
 * Checks if the current environment value matches the expected value.
 *
 * @param {Object} params - The parameters for the function.
 * @param {*} params.data - The data used to determine the current environment value.
 * @param {*} [params.expected=true] - The expected value to compare against the current environment value.
 * @param {boolean} [params.ignoreCase=false] - Whether to ignore case when comparing string values.
 * @param {string} params.name - The name of the environment variable to check.
 * @returns {boolean} - Returns true if the current environment value matches the expected value, otherwise false.
 */
export function isEnvironmentValue({ data, expected = true, ignoreCase = false, name }) {
  const current = getEnvironmentValue({ data, name });
  if (typeof current === "string" && typeof expected === "string" && ignoreCase)
    return current.toLowerCase() === expected.toLowerCase();
  return current === expected;
}
