<template>
  <DialogForm
    :default-payload="defaultInteressado"
    :external-errors="externalErrors"
    :external-errors-field-map="{ codigo: 'codigoInteressado' }"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload }">
      <AccountDialog
        ref="accountDialog"
        field="interessado"
        :is-edit-mode="isEditMode"
        :tipo-codigo.sync="payload.tipoCodigo"
        :codigo.sync="payload.codigo"
        :nome.sync="payload.nome"
        :email.sync="payload.email"
        :celular.sync="payload.celular"
      />
    </template>
    <template #actions>
      <slot
        name="actions"
        :clear-account-data="$refs.accountDialog.reset"
      />
      <ButtonsSaveBack @back="$emit('back')" />
    </template>
  </DialogForm>
</template>

<script>
import { ButtonsSaveBack, DialogForm } from "@/lib";

import { AccountDialog } from "../account-dialog";

export default {
  components: {
    AccountDialog,
    ButtonsSaveBack,
    DialogForm,
  },
  name: "StakeholderForm",
  props: {
    defaultInteressado: {
      default: () => ({}),
      type: Object,
    },
    externalErrors: {
      default: null,
      type: [Object, Error],
    },
    isEditMode: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
