import { isObjectFull } from "@/lib";

export function isRequesterWithWebhook(requester) {
  if (isRequesterPessoa(requester)) return false;
  return isObjectFull(requester.webhook);
}

export function isRequesterSistema(requester) {
  return requester.tipoConta === "SISTEMA";
}

export function isRequesterPessoa(requester) {
  return requester.tipoConta === "PESSOA";
}
