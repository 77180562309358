export const ativoSearchEnum = [
  {
    color: "info",
    isEnum: item => item.ativo,
    text: "Ativo",
  },
  {
    color: "secondary",
    isEnum: item => !item.ativo,
    text: "Inativo",
  },
];
