import { parseRequesterItemToRecord, parseRequesterRecordToItem } from "@/data/requisitantes-helpers";
import { createCollectionStoreModule } from "@/lib";

export function createRequisitantesStore({ api, pageLimit, pollingSeconds }) {
  const baseEndpoint = "/v1/contas/perfil/REQUISITANTE";

  return createCollectionStoreModule({
    actions: {
      async testWebhook({ dispatch }, idRequisitante) {
        const payload = {
          endpoint: `/v1/webhook/validar/${idRequisitante}`,
          method: "get",
        };
        return dispatch("requestAction", payload);
      },
    },
    api,
    editEndpoint: baseEndpoint,
    getters: {
      currentRequisitante(state, getters, rootState, rootGetters) {
        const codigo = rootGetters["auth/codigo"];
        const currentRequisitante = getters.getItemByFieldValue("codigo", codigo);
        return currentRequisitante;
      },
      currentRequisitanteGrupos(state, getters) {
        const currentRequisitanteId = getters.currentRequisitanteId;
        return getters.requisitanteGrupos(currentRequisitanteId);
      },
      currentRequisitanteId(state, getters) {
        return getters.currentRequisitante
          ? getters.currentRequisitante.id
          : null;
      },
      requisitanteGrupos:
        (state, getters, rootState, rootGetters) => (requisitanteId) => {
          if (!requisitanteId) return [];

          const requisitante = getters.getItemById(requisitanteId);
          const gruposIds = requisitante ? requisitante.grupos : [];
          const grupos = rootGetters["grupos/filterItemsByFieldWithSomeValue"]("id", gruposIds);

          return grupos;
        },
    },
    newEndpoint: baseEndpoint,
    pageLimit,
    parseItemToRecord: parseRequesterItemToRecord,
    parseRecordToItem: parseRequesterRecordToItem,
    pollingSeconds,
    queryEndpoint: baseEndpoint,
  });
}
