var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AsyncExecute", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ isBusy, execute, lastError }) {
          return [
            _c(
              "OverlayBase",
              { attrs: { "is-busy": isBusy } },
              [
                _c("DescriptionText", [
                  _vm._v(
                    "Aproveite para registrar um dispositivo agora e na próxima vez que for entrar no NeoSigner poderá utilizar um método mais simples de autenticação."
                  ),
                ]),
                _vm._v(" "),
                _c("DialogForm", {
                  attrs: { "external-errors": lastError },
                  on: {
                    save: function ($event) {
                      return execute(_vm.handleRegister)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c("ButtonSecondary", {
                              attrs: {
                                label: "Talvez mais tarde",
                                field: "later",
                              },
                              on: { click: _vm.handleLater },
                            }),
                            _vm._v(" "),
                            _c("ButtonPrimary", {
                              attrs: {
                                label: "Registrar meu dispositivo",
                                field: "register",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }