import "core-js/stable"; // core e regenerator possibilitam o uso das notações async e await em funções assíncronas em conjunto com babel
import { createApi } from "@/api";
import { installComponentsGlobalDependencies, installValidationSuite } from "@/lib";
import "regenerator-runtime/runtime";
import Vue from "vue";

import manifest from "../package.json";
import "./app/design"; // carrega os estilos personalizados e também das bibliotecas bootstrap e bootstrap-vue
import { initEnvironment } from "./app/environment";
import { initApp } from "./app/init-app";
import { initLogger } from "./app/logger";
import { initRouter } from "./app/router";
import { initStore } from "./app/store";

async function main() {
  Vue.config.productionTip = false;

  const environment = initEnvironment(manifest);

  const environmentBaseUrl = environment.get("WEB_API_BASE_URL");
  const api = createApi({ environmentBaseUrl, getToken });

  const store = await initStore({ api, environment });

  const logger = initLogger({ environment, globals: { Vue, window }, store });

  // injetando propriedades e funções comuns em todos os componentes
  installComponentsGlobalDependencies(Vue);

  const router = initRouter({ environment, store });
  const app = initApp({ api, environment, logger, router, store });

  // instala componentes, regras e diretivas ligadas à validação de entradas do usuário
  installValidationSuite();

  // expondo globals em window para uso pela suite de testes e funções setup que não conseguem acessar a instância de vue. poderá ser substituído por provide/inject após migração para vue 3.x
  window.$app = app;
  window.$environment = environment;
  window.$store = store;

  app.$mount("#app");

  // contato para ver se o servidor está acessível
  await api.request({ endpoint: "/v1/controlador/hello", method: "get" });

  logger.log(`${manifest.name} v${manifest.version} inicializado`);

  function getToken() {
    return store.getters["auth/token"];
  }
}

main();
