import { SYSLOG_SEVERITIES } from "@/lib";

export function silenceUnwantedWarnings(logger) {
  return (msg, vm, trace) => {
    // alertas conhecidos que não tem ação;
    const ignoredWarnings = [
      "$attrs is readonly",
      "$listeners is readonly",
    ];
    if (ignoredWarnings.some(warning => msg.includes(warning))) return;

    // warnings do vue não são exibidos em produção, logger incluído aqui para fins de depuração em ambiente local
    logger.log({ message: msg, meta: { trace, vm }, severity: SYSLOG_SEVERITIES.DEBUG });
  };
}
