import { createCollectionStoreModule } from "@/lib";

export function createClientesStore({ api, pageLimit, pollingSeconds }) {
  const clientsBaseEndpoint = "/v1/clientes";

  return createCollectionStoreModule({
    api,
    editEndpoint: clientsBaseEndpoint,
    newEndpoint: clientsBaseEndpoint,
    pageLimit,
    parseItemToRecord: (item) => {
      const isAdding = !item.cliente;
      if (isAdding) return item;

      // adapta campos de acordo com sintaxe do payload exigido pelo backend na inclusão
      return {
        contratante: {
          estrangeiro: false,
          identificador: item.cliente.cnpj,
          nome: item.cliente.nome,
          sigla: item.cliente.sigla,
          tecnicosProducao: [
            {
              cpf: item.gestor.codigo,
              email: item.gestor.email,
              nome: item.gestor.nome,
              paisIso3: "BRA",
              telefone: item.gestor.telefone,
              telefoneSecundario: "",
              tipoCodigo: item.gestor.tipoCodigo,
            },
          ],
          tipoCliente: "PJ",
        },
      };
    },
    pollingSeconds,
    queryEndpoint: clientsBaseEndpoint,
  });
}
