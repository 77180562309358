import { ROUTING_ACCESSES, listCrumbsFlowsManagement } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-management-add-page.vue"
  );

export function createFlowsManagementAddRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.REQUESTER],
      crumbs: [...listCrumbsFlowsManagement(), { text: "Inclusão de Fluxo" }],
      layout: "LayoutNav",
    },
    path: "/flows-management/add",
  };
}
