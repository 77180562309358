import { ROUTING_ACCESSES, listCrumbsRequesters } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./requesters-add-page.vue"
  );

export function createRequestersAddRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER],
      crumbs: [...listCrumbsRequesters(), { text: "Inclusão de Requisitante" }],
      layout: "LayoutNav",
    },
    path: "/requesters/add",
  };
}
