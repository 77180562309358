import { isObjectFull } from "@/lib";

export function parseWebhookItemToRecord(clientWebhook) {
  if (!isObjectFull(clientWebhook)) return null;

  const { method, url } = clientWebhook;
  const serverWebhook = { metodo: method, url };
  serverWebhook.apikey = parseApikeyItemToRecord(clientWebhook);
  serverWebhook.oauth2 = parseOauth2ItemToRecord(clientWebhook);
  return serverWebhook;
}

export function parseWebhookRecordToItem(serverWebhook) {
  if (!isObjectFull(serverWebhook)) return null;

  const { apikey, metodo: method, oauth2, url } = serverWebhook;
  const apikeyClientData = parseApikeyRecordToItem(apikey);
  const oauth2ClientData = parseOauth2RecordToItem(oauth2);

  const clientWebhook = {
    method,
    url,
    ...apikeyClientData,
    ...oauth2ClientData,
  };

  return clientWebhook;
}

function parseApikeyItemToRecord({ apikeyHas, apikeyName, apikeyValue }) {
  if (!apikeyHas) return null;
  return { chave: apikeyName, valor: apikeyValue };
}

function parseOauth2ItemToRecord({ oauth2Client, oauth2Has, oauth2Scope, oauth2Secret, oauth2Type, oauth2Url }) {
  if (!oauth2Has) return null;
  return { clientId: oauth2Client, clientSecret: oauth2Secret, scope: oauth2Scope, tokenUrl: oauth2Url, type: oauth2Type };
}

function parseApikeyRecordToItem(serverApikey) {
  const apikeyHas = isObjectFull(serverApikey);
  const apikeyName = apikeyHas ? serverApikey?.chave : null;
  const apikeyValue = apikeyHas ? serverApikey?.valor : null;
  return { apikeyHas, apikeyName, apikeyValue };
}

function parseOauth2RecordToItem(serverOauth2) {
  const oauth2Has = isObjectFull(serverOauth2);
  const oauth2Client = oauth2Has ? serverOauth2?.clientId : null;
  const oauth2Secret = oauth2Has ? serverOauth2?.clientSecret : null;
  const oauth2Type = oauth2Has ? serverOauth2?.type : "CLIENT_CREDENTIALS";
  const oauth2Url = oauth2Has ? serverOauth2?.tokenUrl : null;
  const oauth2Scope = oauth2Has ? serverOauth2?.scope : null;
  return { oauth2Client, oauth2Has, oauth2Scope, oauth2Secret, oauth2Type, oauth2Url };
}
