import { ROUTING_ACCESSES, listCrumbsClients } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./clients-edit-page.vue"
  );

export function createClientsEditRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR],
      crumbs: [...listCrumbsClients(), { text: "Edição de Cliente" }],
      layout: "LayoutNav",
    },
    path: "/clients/edit/:itemId",
    props: true,
  };
}
