<template>
  <BNavItem
    :link-attrs="linkAttrs"
    :link-classes="[
      'd-flex',
      'justify-content-between',
      'pr-4',
      level === 1 ? 'pl-3' : 'pl-5',
      'text-white',
      'left-block-primary-500-on-hover',
    ]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </BNavItem>
</template>

<script>
import { BNavItem } from "../bv";
export default {
  components: { BNavItem },
  computed: {
    linkAttrs() {
      if (!this.dataTest) return null;
      return {
        "data-test": this.dataTest,
      };
    },
  },
  name: "SideBarItem",
  props: {
    dataTest: {
      default: null,
      type: String,
    },
    level: {
      default: 1,
      type: Number,
    },
  },
};
</script>

<style scoped>
a {
  color: var(--w-color-neutral-10);
  border-left: var(--w-border-size-30) solid transparent;
}

a.router-link-exact-active {
  color: var(--w-color-success-50);
  border-left: var(--w-border-size-30) solid var(--w-color-success-50);
}

a:hover {
  border-left: var(--w-border-size-30) solid var(--w-color-primary-40);
  color: var(--w-color-primary-40) !important;
  background-color: var(--w-color-neutral-10);
}

a:hover::v-deep svg {
  fill: var(--w-color-primary-40) !important;
}
</style>
