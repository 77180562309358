import { ROUTING_ACCESSES } from "@/views/widgets";

import component from "./maintenance-page.vue";

export function createMaintenanceRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.PUBLIC],
      layout: "LayoutHero",
    },
    path: "/maintenance",
  };
}
