<template>
  <span
    class="spacer"
    :style="cssVars"
  />
</template>

<script>
export default {
  computed: {
    cssVars() {
      const display = this.block ? "block" : "inline-block";
      const vertical = this.vertical
        ? `var(--w-size-${this.vertical}, 0)`
        : "0";
      const horizontal = this.horizontal
        ? `var(--w-size-${this.horizontal}, 0)`
        : "0";
      return {
        "--display": display,
        "--horizontal": horizontal,
        "--vertical": vertical,
      };
    },
  },
  name: "SpacerBase",
  props: {
    block: {
      default: false,
      type: Boolean,
    },
    horizontal: {
      default: null,
      type: String,
    },
    vertical: {
      default: null,
      type: String,
    },
  },
};
</script>

<style scoped>
.spacer {
  display: var(--display);
  height: var(--vertical);
  width: var(--horizontal);
}
</style>
