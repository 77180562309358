var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BTable",
        _vm._g(
          _vm._b(
            {
              staticClass: "list-base-table",
              attrs: {
                id: _vm.camelCase("list", _vm.field),
                items: _vm.items,
                fields: _vm.styledFields,
                "sort-by": _vm.initialSort.field,
                selectable: !!_vm.select,
                "select-mode": _vm.select,
                busy: _vm.isLoading,
                "primary-key": _vm.primaryKey,
                "per-page": _vm.itemsPerPage,
                "current-page": _vm.currentPage,
                "sort-desc": _vm.initialSort.direction === "desc",
                "sort-icon-left": "",
                striped: "",
                hover: "",
                stacked: "md",
                responsive: "",
                "show-empty": "",
                "empty-text": "Nenhum item encontrado",
                "empty-filtered-text": "Nenhum item encontrado na pesquisa",
                "tbody-tr-class": { "has-default-action": !!_vm.defaultAction },
              },
              on: { "row-clicked": _vm.handleClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-4" },
                          [
                            _c("BSpinner", { staticClass: "align-middle" }),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Carregando...")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            "BTable",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _vm.items.length > _vm.itemsPerPage
        ? _c("BPagination", {
            attrs: {
              id: _vm.camelCase("pagination", _vm.field),
              "total-rows": _vm.items.length,
              "per-page": _vm.itemsPerPage,
              "aria-controls": _vm.camelCase("pagination", _vm.field),
              "first-number": "",
              "last-number": "",
              align: "center",
            },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }