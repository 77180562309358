import { parseWebhookItemToRecord, parseWebhookRecordToItem } from "../webhooks-helpers";

export function parseRequesterRecordToItem(serverRequester) {
  const { celular, codigo, email, id, idCliente, nome, perfil: perfilServer, tipoCodigo, tipoConta, webhook: serverWebhook } = serverRequester;
  const { ativo, grupos, nome: perfil } = perfilServer;

  const clientRequester = { ativo, celular, codigo, email, grupos, id, idCliente, nome, perfil, tipoCodigo, tipoConta };
  clientRequester.webhook = parseWebhookRecordToItem(serverWebhook);

  return clientRequester;
}

export function parseRequesterItemToRecord(clientRequester) {
  const { ativo = true, celular, codigo, email, grupos, id, idCliente, nome, perfil: perfilClient = "REQUISITANTE", tipoCodigo, tipoConta = "PESSOA", webhook: clientWebhook } = clientRequester;

  const perfil = { ativo, grupos, nome: perfilClient };
  const webhook = parseWebhookItemToRecord(clientWebhook);

  return { celular, codigo, email, id, idCliente, nome, perfil, tipoCodigo, tipoConta, webhook };
}
