export function resolveStage() {
  const hostName = window.location.hostname;

  const isCorporativo = hostName.includes("serpro.gov.br");

  if ((hostName.includes("localhost") || hostName.includes("web-app")) && !isCorporativo)
    return "loc";

  if (hostName.includes("dev-") && isCorporativo) return "dev";
  if (hostName.includes("hom.") && isCorporativo) return "hom";
  if (hostName.includes("sta.") && isCorporativo) return "sta";
  if (hostName.includes("neosigner") && isCorporativo) return "pro";

  return "unknown";
}
