var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonIcon", {
        attrs: {
          field: _vm.field,
          hide: _vm.composedHide,
          disabled: _vm.composedDisabled,
          icon: "edit-square",
          tooltip: "apontar posição do selo de assinatura",
        },
        on: { click: _vm.handleClick },
      }),
      _vm._v(" "),
      _vm.hasModal
        ? _c("FlowFormDocumentsTabManagerStamperModal", {
            ref: "stamper",
            attrs: {
              field: _vm.camelCase("stamper", "modal"),
              fluxo: _vm.fluxo,
              documento: _vm.documento,
              "download-blob": _vm.downloadBlob,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }