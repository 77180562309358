import { ROUTING_ACCESSES, listCrumbsManagers } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./managers-edit-page.vue"
  );

export function createManagersEditRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER],
      crumbs: [...listCrumbsManagers(), { text: "Edição de Gestor" }],
      layout: "LayoutNav",
    },
    path: "/managers/edit/:itemId",
    props: true,
  };
}
