import { ROUTING_ACCESSES, listCrumbsRequesters } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./requesters-edit-page.vue"
  );

export function createRequestersEditRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.ADMINISTRATOR, ROUTING_ACCESSES.MANAGER],
      crumbs: [...listCrumbsRequesters(), { text: "Edição de Requisitante" }],
      layout: "LayoutNav",
    },
    path: "/requesters/edit/:itemId",
    props: true,
  };
}
