import { createCollectionStoreModule } from "@/lib";

import { parseFluxoRecordToItem } from "../fluxos-common";

export function createFluxosArchivedStore({ api, pageLimit, pollingSeconds }) {
  const baseEndpoint = "/v1/fluxos/arquivados";
  return createCollectionStoreModule({
    api,
    pageLimit,
    parseRecordToItem: parseFluxoRecordToItem,
    pollingSeconds,
    queryEndpoint: baseEndpoint,
  });
}
