export function userData(store) {
  const userData = store.getters["auth/isSignedIn"]
    ? {
        email: store.getters["auth/email"],
        idCliente: store.getters["auth/idCliente"],
        idConta: store.getters["auth/idConta"],
        nomeCliente: store.getters["auth/nomeCliente"],
        perfil: store.getters["auth/perfilIfSignedIn"],
      }
    : {};
  return userData;
}
