import { ROUTING_ACCESSES, isAccessible } from "@/views/widgets";

export function createGuardRoute({ isOnMaintenance, isSignedInGetter, profileGetter }) {
  const maintenance = "/maintenance";

  if (isOnMaintenance) {
    return (to, from, next) => {
      return to.path === maintenance ? next() : next(maintenance);
    };
  }

  let firstAttemptedRoute = null;
  let wasFirstAttemptedRedirected = false;
  return (to, from, next) => {
    if (!firstAttemptedRoute) {
      firstAttemptedRoute = to;
    }

    if (shouldRedirectToFirstAttempt()) {
      wasFirstAttemptedRedirected = true;
      return next(firstAttemptedRoute.fullPath);
    }

    if (!isRouteAllowed(to)) {
      return next({
        path: "/default",
        // assim parâmetros embutidos no link original podem ser reusados na autenticação o outro redirecionamento prévio ao link desejado originalmente
        query: firstAttemptedRoute.query,
      });
    }

    next();
  };

  function isRouteAllowed(route) {
    if (!route) return false;
    if (route.path === maintenance) return false;
    return isAccessible(profileGetter(), route.meta.access);
  }

  function shouldRedirectToFirstAttempt() {
    if (wasFirstAttemptedRedirected) return false;
    if (!isSignedInGetter()) return false;

    const { access } = firstAttemptedRoute.meta;
    const isFirstAttemptAnExternalRoute = !!access.find(a => [ROUTING_ACCESSES.NONE, ROUTING_ACCESSES.PUBLIC].includes(a));
    if (isFirstAttemptAnExternalRoute) return false;

    const isFirstAttemptAnAllowedRoute = isRouteAllowed(firstAttemptedRoute);
    return isFirstAttemptAnAllowedRoute;
  }
}
