<template>
  <ModalBase
    ref="modal"
    size="lg"
  >
    <template #body="{ save, cancel }">
      <DialogForm
        :default-payload="defaultInteressado"
        show-action-divider
        @save="save($event)"
      >
        <template #body="{ payload }">
          <RowForm>
            <ColBase>
              <ControlSelectSingle
                v-model="payload.papel"
                field="papel"
                :options="['ASSINANTE', 'REVISOR']"
                rules="required"
                label="Papel"
              />
            </ColBase>
          </RowForm>
          <AccountDialog
            ref="AccountDialog"
            field="interessado"
            :tipo-codigo.sync="payload.tipoCodigo"
            :codigo.sync="payload.codigo"
            :nome.sync="payload.nome"
            :email.sync="payload.email"
            :celular.sync="payload.celular"
          />
        </template>
        <template #actions>
          <ButtonTertiary
            field="reset"
            label="Limpar"
            @click="handleReset"
          />
          <ButtonsSaveBack @back="cancel()" />
        </template>
      </DialogForm>
    </template>
  </ModalBase>
</template>

<script>
import { ButtonTertiary, ButtonsSaveBack, ColBase, ControlSelectSingle, DialogForm, ModalBase, RowForm } from "@/lib";

import { AccountDialog } from "../account-dialog";

export default {
  components: {
    AccountDialog,
    ButtonTertiary,
    ButtonsSaveBack,
    ColBase,
    ControlSelectSingle,
    DialogForm,
    ModalBase,
    RowForm,
  },
  data() {
    return {
      defaultInteressado: {
        papel: "ASSINANTE",
        resposta: {
          data: null,
          status: "PENDENTE",
        },
        tipoCodigo: "CPF",
      },
    };
  },
  methods: {
    handleReset() {
      this.$refs.AccountDialog.reset();
    },
    show() {
      return this.$refs.modal.show();
    },
  },
  name: "FlowFormStakeholdersTabManagerAttachModal",
};
</script>
