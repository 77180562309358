var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ControlSelectSingle",
    _vm._g(
      _vm._b(
        {
          attrs: {
            field: "grupo",
            options: _vm.requisitantegroupsWithInactiveLabel,
            "value-field": "id",
            "text-field": "nome",
            rules: "required",
            label: "Grupo",
          },
        },
        "ControlSelectSingle",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }