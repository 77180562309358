import { assertSeverities } from "./severities";

export function isEquallyOrMoreImportant(severity, than) {
  const [localSeverity, localThan] = assertSeverities(severity, than);
  return localSeverity <= localThan;
}

export function isMoreImportant(severity, than) {
  const [localSeverity, localThan] = assertSeverities(severity, than);
  return localSeverity < localThan;
}
