var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ButtonIcon",
    _vm._b(
      {
        attrs: {
          icon: "download",
          tooltip: "download",
          hide: _vm.hide || !_vm.downloadBlob,
        },
        on: { click: _vm.handleDownload },
      },
      "ButtonIcon",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }