var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DialogForm", {
    attrs: {
      "default-payload": _vm.defaultGrupo,
      "external-errors": _vm.externalErrors,
      "show-action-divider": "",
    },
    on: {
      save: function ($event) {
        return _vm.$emit("save", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "body",
          fn: function ({ payload }) {
            return [
              _c(
                "RowForm",
                [
                  _c(
                    "ColBase",
                    [
                      _c("ControlInput", {
                        attrs: {
                          field: "nome",
                          rules: "required|min:3|max:255",
                          label: "Nome",
                          disabled: _vm.editDisabled,
                        },
                        model: {
                          value: payload.nome,
                          callback: function ($$v) {
                            _vm.$set(payload, "nome", $$v)
                          },
                          expression: "payload.nome",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "RowForm",
                [
                  _c(
                    "ColBase",
                    [
                      _c("ControlSelectMulti", {
                        attrs: {
                          field: "gestores",
                          options: _vm.gestores,
                          "value-field": "id",
                          "text-field": "nome",
                          rules: "required",
                          rows: 3,
                          label: "Gestores",
                          placeholder:
                            "Escolha os demais gestores desse grupo.",
                          disabled: _vm.editDisabled,
                        },
                        model: {
                          value: payload.gestores,
                          callback: function ($$v) {
                            _vm.$set(payload, "gestores", $$v)
                          },
                          expression: "payload.gestores",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "RowForm",
                [
                  _c(
                    "ColBase",
                    [
                      _c("ControlTextarea", {
                        attrs: {
                          field: "descricao",
                          rules: "required|min:3|max:255",
                          label: "Descrição",
                          disabled: _vm.editDisabled,
                        },
                        model: {
                          value: payload.descricao,
                          callback: function ($$v) {
                            _vm.$set(payload, "descricao", $$v)
                          },
                          expression: "payload.descricao",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.hideAtivo
                ? _c(
                    "RowForm",
                    [
                      _c(
                        "ColBase",
                        [
                          _c("ControlCheckbox", {
                            attrs: {
                              field: "ativo",
                              hide: _vm.hideAtivo,
                              placeholder: "Ativar Grupo",
                              switch: "",
                              disabled: _vm.editDisabled,
                            },
                            model: {
                              value: payload.ativo,
                              callback: function ($$v) {
                                _vm.$set(payload, "ativo", $$v)
                              },
                              expression: "payload.ativo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [_vm._t("actions")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }