var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ButtonPrimary, {
    attrs: { field: "signOut", label: "Sair" },
    on: {
      click: function ($event) {
        return _vm.$emit("signOut")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }