<template>
  <CardBase
    :header-text="headerText"
    :show-overlay="showOverlay"
    :show-linear="showLinear"
  >
    <slot />
  </CardBase>
</template>

<script>
import { CardBase } from "../dialogs";

export default {
  components: { CardBase },
  name: "ItemPage",
  props: {
    headerText: {
      required: true,
      type: String,
    },
    showLinear: {
      default: false,
      type: Boolean,
    },
    showOverlay: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
