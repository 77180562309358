import { ROUTING_ACCESSES } from "@/views/widgets";

import component from "./auth-page.vue";

export function createAuthRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.NONE],
      layout: "LayoutHero",
    },
    path: "/auth",
    props: route => ({
      linkId: route.query.linkId,
      linkToken: route.query.linkToken,
      perfil: route.query.perfil,
      session: route.query.session,
    }),
  };
}
