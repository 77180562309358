<template>
  <InfoText>{{ summary }}</InfoText>
</template>

<script>
import { isInteressadoWithStatusAprovado, isInteressadoWithStatusBloqueado, isInteressadoWithStatusPendente, isInteressadoWithStatusRejeitado } from "@/data";
import { InfoText } from "@/lib";

export default {
  components: { InfoText },
  computed: {
    aprovaram() {
      return this.buildPhrase(this.aprovaramLength, "aprovou", "aprovaram");
    },
    aprovaramLength() {
      return this.respostas.filter(isInteressadoWithStatusAprovado).length;
    },
    bloqueados() {
      return this.buildPhrase(
        this.bloqueadosLength,
        "está bloqueado",
        "estão bloqueados",
      );
    },
    bloqueadosLength() {
      return this.respostas.filter(isInteressadoWithStatusBloqueado).length;
    },
    pendentes() {
      return this.buildPhrase(
        this.pendentesLength,
        "está pendente",
        "estão pendentes",
      );
    },
    pendentesLength() {
      return this.respostas.filter(isInteressadoWithStatusPendente).length;
    },
    phrasesWithText() {
      return [
        { length: this.aprovaramLength, text: this.aprovaram },
        { length: this.rejeitaramLength, text: this.rejeitaram },
        { length: this.pendentesLength, text: this.pendentes },
        { length: this.bloqueadosLength, text: this.bloqueados },
      ]
        .filter(phrase => phrase.length > 0)
        .map(phrase => phrase.text);
    },
    rejeitaram() {
      return this.buildPhrase(this.rejeitaramLength, "rejeitou", "rejeitaram");
    },
    rejeitaramLength() {
      return this.respostas.filter(isInteressadoWithStatusRejeitado).length;
    },
    summary() {
      return this.phrasesWithText.reduce((acc, phrase, i, phrases) => {
        const isLonely = this.totalLength === 1;
        if (isLonely) return `${acc} ${phrase}`;

        const isLastButNotFirst = i === phrases.length - 1 && i !== 0;
        if (isLastButNotFirst) return `${acc} e ${phrase}`;

        return `${acc}, ${phrase}`;
      }, this.totalPhrase);
    },
    totalLength() {
      return this.respostas.length;
    },
    totalPhrase() {
      const length = this.totalLength;
      if (length === 1) return "O único interessado";
      return `Dos ${length} interessados`;
    },
  },
  methods: {
    buildPhrase(length, singular, plural) {
      if (length === 0) return "";
      if (length === 1) return length === this.totalLength ? singular : `um ${singular}`;
      return `${length} ${plural}`;
    },
  },
  name: "ApprovalsSummary",
  props: {
    respostas: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
