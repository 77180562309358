export function loadSystemEnvironment() {
  return {
    language: navigator.language || navigator.userLanguage,
    origin: () => window.location.origin,
    os: getOS(),
    platform: navigator.platform,
    screen: () => `${window.screen.width}x${window.screen.height}`,
    url: () => window.location.href,
    userAgent: navigator.userAgent,
  };
}

function getOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return "Mac OS";
  }
  if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
    return "Windows";
  }
  if (/Linux/.test(userAgent)) {
    return "Linux";
  }

  return "Unknown";
}
