import { parseString } from "@/lib";

export function parseInteressadoDeFluxoRecordToItem(serverInteressado) {
  const { celular, codigo, email, id, nome, perfil, tipoCodigo } = serverInteressado;
  const { ordem, papel, resposta: respostaServer } = perfil;
  const resposta = parseRespostaRecordToItem(respostaServer);

  return { celular, codigo, email, id, nome, ordem, papel, resposta, tipoCodigo };
}

function parseRespostaRecordToItem(respostaServer) {
  if (!respostaServer) return null;

  const { dataResposta, parecer: parentParecer, status } = respostaServer;
  const { interessadosIds, parecer: parecerSerialized } = parentParecer ?? { interessadosIds: [], parecer: null };
  // mesmo sendo string, o valor em parecer pode não parseable
  const parecer = parseString(parecerSerialized);

  return { dataResposta, interessadosIds, parecer, status };
}

export function parseInteressadoDeFluxoItemToRecord(clientInteressado) {
  const { celular, codigo, email, id, nome, ordem, papel, tipoCodigo } = clientInteressado;
  return {
    celular,
    codigo,
    email,
    id,
    nome,
    perfil: { ordem, papel },
    tipoCodigo,
  };
}
