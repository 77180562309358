<template>
  <MessageBase
    ref="modal"
    variant="success"
    title="Sucesso"
    :message="message"
    :message-detail="[
      'A sua resposta foi recebida com sucesso.',
      'Um email será enviado à você para acesso aos documentos. Caso haja mais de um interessado, o email seguirá após a resposta dos demais',
    ]"
    icon="success"
  >
    <template #default="{ save, cancel }">
      <ButtonsOut
        v-if="forceSignOut"
        @signOut="cancel"
      />
      <ButtonsListOut
        v-else
        @signOut="cancel"
        @list="save"
      />
    </template>
  </MessageBase>
</template>

<script>
import { MessageBase } from "@/lib";
import { mapActions, mapGetters } from "vuex";

import ButtonsListOut from "./buttons-list-out.vue";
import ButtonsOut from "./buttons-out.vue";

export default {
  components: { ButtonsListOut, ButtonsOut, MessageBase },
  computed: {
    ...mapGetters("auth", ["hasLinkAutenticado"]),
    forceSignOut() {
      // autenticação via link acompanha regra de negócio que o usuário deve realizar sign out após assinatura de documento.
      return this.hasLinkAutenticado;
    },
    message() {
      return this.forceSignOut
        ? "Obrigado por usar o Neosigner"
        : "Agora você deseja sair do Neosigner ou listar seus fluxos de assinatura?";
    },
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    async show() {
      const answer = await this.$refs.modal.show();
      if (answer) {
        this.$router.push("/flows-engagement/list");
      }
      else {
        this.signOut();
      }
    },
  },
  name: "ApprovalSuccessModal",
};
</script>
