import { ROUTING_ACCESSES, listCrumbsFlowsEngagement } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-engagement-acknowledged-page.vue"
  );

export function createFlowsEngagementAcknowledgedRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.STAKEHOLDER],
      crumbs: [...listCrumbsFlowsEngagement(), { text: "Verificação de Documento" }],
      layout: "LayoutNav",
    },
    path: "/flows-engagement/acknowledgment/:fluxoId/:documentoId",
    props: true,
  };
}
