<template>
  <ControlInput
    :value="nome"
    field="requisitante"
    :hidden="hidden"
    :disabled="true"
    label="Criado por:"
  />
</template>

<script>
import { ControlInput } from "@/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    ControlInput,
  },
  computed: {
    ...mapGetters("requisitantes", ["getItemById"]),
    nome() {
      if (!this.idRequisitante) return "";

      const requisitante = this.getItemById(this.idRequisitante);
      if (!requisitante) return "";
      return requisitante.nome;
    },
  },
  name: "FlowFormBasicTabControlRequester",
  props: {
    hidden: {
      default: false,
      type: Boolean,
    },
    idRequisitante: {
      default: null,
      type: String,
    },
  },
};
</script>
