<template>
  <CenterBase>
    <ColorShifter desktop="var(--w-color-neutral-10)">
      <DisplayText :size="1">
        404
      </DisplayText>
      <DisplayText :size="5">
        Oops! A página que você tentou acessar não foi encontrada.
      </DisplayText>
    </ColorShifter>
    <SpacerBase
      vertical="20"
      block
    />
    <ButtonSecondary
      class="mt-3 w-auto"
      label="Ir para o início"
      @click="$router.push('/default')"
    />
  </CenterBase>
</template>

<script>
import { ButtonSecondary, CenterBase, ColorShifter, DisplayText, SpacerBase } from "@/lib";

export default {
  components: { ButtonSecondary, CenterBase, ColorShifter, DisplayText, SpacerBase },
  name: "NotFoundPage",
};
</script>
