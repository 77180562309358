import { AppError } from "@/lib/error";
import { DEFAULT_SEVERITY, SYSLOG_SEVERITIES } from "@/lib/severities";

import { LOG_ERRORS } from "./errors";

export function assertEffects(effects = []) {
  if (!Array.isArray(effects))
    AppError.throw({ message: LOG_ERRORS.EFFECTS_NOT_ARRAY, severity: SYSLOG_SEVERITIES.ERROR });

  return effects.map((effect) => {
    if (typeof effect !== "function" && typeof effect.trigger !== "function")
      AppError.throw({ message: LOG_ERRORS.EFFECT_NOT_FUNCTION, severity: SYSLOG_SEVERITIES.ERROR });

    return {
      severity: effect.severity ?? DEFAULT_SEVERITY,
      trigger: effect.trigger ?? effect,
    };
  });
}
