/* eslint-disable perfectionist/sort-objects */

// os syslog severitiy levels são adaptados da RFC 5424 The Syslog Protocol -https://datatracker.ietf.org/doc/html/rfc5424#section-6.1. eles são usados de forma padronizada por outros módulos como error e logger.
export const SYSLOG_SEVERITIES = {
  EMERGENCY: 0, // system is unusable
  ALERT: 1, // action must be taken immediately
  CRITICAL: 2, // critical conditions
  ERROR: 3, // error conditions
  WARNING: 4, // warning conditions
  NOTICE: 5, // normal but significant condition
  INFO: 6, // informational messages
  DEBUG: 7, // debug-level messages
};

export const DEFAULT_SEVERITY = SYSLOG_SEVERITIES.DEBUG;

export function assertSeverity(severity) {
  return typeof severity === "number"
    && severity >= 0
    && severity <= 7
    ? severity
    : DEFAULT_SEVERITY;
}

export function assertSeverities(...args) {
  return args.map(assertSeverity);
}
