var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonSecondary", {
        attrs: {
          field: _vm.camelCase("interessado", "link"),
          hide: _vm.hide,
          label: "+ Vincular Interessado",
          size: "sm",
        },
        on: { click: _vm.handleAttach },
      }),
      _vm._v(" "),
      !_vm.hide
        ? _c("FlowFormStakeholdersTabManagerAttachModal", { ref: "modal" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }