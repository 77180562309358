<template>
  <div class="search-text">
    <InputBase
      :id="camelCase('search', field)"
      :value="value"
      debounce="500"
      placeholder="digite para pesquisar..."
      input-class="rounded-pill bg-transparent pr-5"
      v-bind="$attrs"
      @input="$emit('input', $event)"
    />
    <div class="search-text-app-icon-wrapper">
      <AppIcon
        name="search"
        variant="info"
      />
    </div>
  </div>
</template>

<script>
import { camelCase } from "@/lib/strings";

import { InputBase } from "../controls";
import { AppIcon } from "../icons";

export default {
  components: {
    AppIcon,
    InputBase,
  },
  methods: {
    camelCase,
  },
  name: "SearchText",
  props: {
    field: {
      default: "text",
      type: String,
    },
    value: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.search-text {
  position: relative;
}

.search-text-app-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--w-size-20);
  font-size: var(--w-font-size-35);
}
</style>
