import { assertSeverity } from "@/lib/severities";

export class AppError extends Error {
  cause;
  code;
  meta;
  severity;

  constructor(messageOrOptions = {}) {
    const { cause, code, message, meta, severity } = typeof messageOrOptions === "string"
      ? { message: messageOrOptions }
      : messageOrOptions;

    super(message);
    this.message = message;
    this.code = code ?? message;
    this.severity = assertSeverity(severity);
    this.meta = meta ?? {};
    this.cause = cause;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AppError);
    }
  }

  static throw(messageOrOptions) {
    const error = new AppError(messageOrOptions);
    throw error;
  }
}
