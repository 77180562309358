var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FragmentBase",
    [
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("pull", "interessado", _vm.field),
          hide: _vm.hide,
          disabled: _vm.disabledPull,
          icon: "caret-up",
          tooltip: "ordenar para posição anterior",
        },
        on: { click: _vm.handlePull },
      }),
      _vm._v(" "),
      _c("ButtonIcon", {
        attrs: {
          field: _vm.camelCase("push", "interessado", _vm.field),
          hide: _vm.hide,
          disabled: _vm.disabledPush,
          icon: "caret-down",
          tooltip: "ordenar para posição posterior",
        },
        on: { click: _vm.handlePush },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }