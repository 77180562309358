<template>
  <QueryState
    v-slot="{ isBusyWithQuerying, isBusyWithFirstQuerying }"
    :store-modules="storeModules"
    :after-first-query="afterFirstQuery"
  >
    <AsyncExecute v-slot="{ isBusy: isBusyWithExecute, execute, lastError }">
      <!-- o overlay é ativado durante a primeira query para permitir que telas de edição consigam e inclusão consigam carregar os dados originais e de listagem antes de o usuário começar a editar campos. caso contrário ele vai editar campos em branco sem saber o dado real daquele registro que ele está editando  -->
      <CardBase
        :header-text="headerText"
        :show-overlay="isBusyWithExecute || isBusyWithFirstQuerying"
        :show-linear="isBusyWithQuerying"
      >
        <slot
          :execute="execute"
          :last-error="lastError"
        />
      </CardBase>
    </AsyncExecute>
  </QueryState>
</template>

<script>
import { AsyncExecute, QueryState } from "../async";
import { CardBase } from "../dialogs";

export default {
  components: { AsyncExecute, CardBase, QueryState },
  name: "ItemStorePage",
  props: {
    afterFirstQuery: {
      default: null,
      type: Object,
    },
    headerText: {
      required: true,
      type: String,
    },
    storeModules: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
