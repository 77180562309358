import { createCollectionStoreModule } from "@/lib";

import { isArchived, parseDocumentoDeFluxoRecordToItem, parseFluxoItemToRecord, parseFluxoRecordToItem } from "../fluxos-common";

export function createFluxosAdministrativoStore({ api, pageLimit, pollingSeconds }) {
  const flowsBaseEndpoint = "/v1/fluxos";

  return createCollectionStoreModule({
    actions: {
      async archiveFluxo({ dispatch }, fluxo) {
        const payload = {
          endpoint: `${flowsBaseEndpoint}/${encodeURI(fluxo.id)}/arquivar`,
          method: "patch",
        };
        await dispatch("setItemCommand", payload);
      },

      async cancelFluxo({ dispatch }, fluxo) {
        const payload = {
          endpoint: `${flowsBaseEndpoint}/${encodeURI(fluxo.id)}/cancelar`,
          method: "patch",
        };
        await dispatch("setItemCommand", payload);
      },

      async deleteDocumento({ commit, dispatch }, { idDocumento, idFluxo }) {
        try {
          const payload = {
            endpoint: `/v1/documentos/${idDocumento}`,
            method: "delete",
          };

          await dispatch("requestAction", payload);
          commit("excluirDocumentoDeFluxo", { idDocumento, idFluxo });
          return { status: "success" };
        }
        catch (error) {
          return { status: "fail", value: error };
        }
      },

      async finishFluxo({ dispatch }, fluxo) {
        const payload = {
          endpoint: `${flowsBaseEndpoint}/${encodeURI(fluxo.id)}/finalizar`,
          method: "patch",
        };
        await dispatch("setItemCommand", payload);
      },

      async sendLink({ dispatch }, { idFluxo, idRequisitante, interessado }) {
        const record = { idFluxo, idRequisitante, interessados: [interessado] };
        const payload = {
          endpoint: "/v1/reenviar-links",
          method: "patch",
          record,
        };
        await dispatch("requestAction", payload);
      },

      async startFluxo({ dispatch }, fluxo) {
        await dispatch("editItemCommand", fluxo);
        const payload = {
          endpoint: `${flowsBaseEndpoint}/${encodeURI(fluxo.id)}/iniciar`,
          method: "patch",
        };
        await dispatch("setItemCommand", payload);
      },

      async uploadDocumento({ commit, dispatch }, { file, idFluxo }) {
        try {
          const payload = {
            endpoint: "/v1/documentos",
            method: "upload",
            record: { doc: file, idFluxo },
          };
          const serverDocumento = await dispatch("requestAction", payload);
          const clientDocumento = parseDocumentoDeFluxoRecordToItem(serverDocumento);
          commit("incluirDocumentoEmFluxo", clientDocumento);

          // o formato do retorno é importante para que o ui saiba o resultado da operação e mantenha a interface de usuário em sincronia
          return {
            status: "success",
            value: clientDocumento,
          };
        }
        catch (error) {
          return {
            status: "fail",
            value: error,
          };
        }
      },

    },
    api,
    editEndpoint: flowsBaseEndpoint,
    filterItem: item => !isArchived(item),
    mutations: {

      excluirDocumentoDeFluxo: (state, { idDocumento, idFluxo }) => {
        const documentos = state.items[idFluxo].documentos;
        const i = documentos.findIndex(doc => doc.id === idDocumento);
        documentos.splice(i, 1);
      },

      incluirDocumentoEmFluxo: (state, documento) => {
        const documentos = state.items[documento.idFluxo].documentos;
        documentos.push(documento);
      },
    },
    newEndpoint: flowsBaseEndpoint,
    pageLimit,
    parseItemToRecord: parseFluxoItemToRecord,
    parseRecordToItem: parseFluxoRecordToItem,
    pollingSeconds,
    queryEndpoint: flowsBaseEndpoint,
  });
}
