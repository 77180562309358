<template>
  <MenuItem @click="signOut">
    Sair
  </MenuItem>
</template>

<script>
import { MenuItem } from "@/lib";
import { mapActions } from "vuex";

export default {
  components: { MenuItem },
  methods: {
    ...mapActions("auth", ["signOut"]),
  },
  name: "UserMenuSignOut",
};
</script>
