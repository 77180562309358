var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CardBase",
    {
      attrs: {
        "header-text": _vm.headerText,
        "show-overlay": _vm.showOverlay,
        "show-linear": _vm.showLinear,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }