var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OverlayBase",
    { attrs: { "is-busy": _vm.isSearching } },
    [
      !_vm.isEditMode
        ? _c("AlertText", {
            attrs: {
              text: "O Tipo e o Código do usuário não poderão ser alterados no futuro",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            { attrs: { sm: "4" } },
            [
              _c("ControlSelectSingle", {
                attrs: {
                  field: _vm.camelCase("tipoCodigo", _vm.field),
                  value: _vm.tipoCodigo,
                  options: ["CPF", "GENERICO"],
                  disabled: !_vm.arePrimaryFieldsEnabled,
                  label: "tipo",
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("update:tipoCodigo", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ColBase",
            { attrs: { sm: "8" } },
            [
              _c("ControlInput", {
                attrs: {
                  field: _vm.camelCase("codigo", _vm.field),
                  value: _vm.codigo,
                  rules: `required${_vm.tipoCodigo === "CPF" ? "|cpf" : ""}`,
                  mask: _vm.tipoCodigo === "CPF" ? "###.###.###-##" : null,
                  disabled: !_vm.arePrimaryFieldsEnabled,
                  label: "código",
                  "lookup-options": _vm.lookupOptions,
                  "lookup-text-field": "codigo",
                  "lookup-value-field": "nome",
                },
                on: {
                  lookup: _vm.handleLookup,
                  input: function ($event) {
                    return _vm.$emit("update:codigo", $event)
                  },
                  blur: _vm.handleCodigoSearch,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isEditMode && _vm.tipoCodigo === "GENERICO"
        ? _c("AlertText", {
            attrs: {
              variant: "info",
              text: "Usuário do tipo GENERICO não pode assinar fluxos que requeiram uso de certificado ICP-Brasil",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            { attrs: { sm: "" } },
            [
              _c("ControlInput", {
                attrs: {
                  field: _vm.camelCase(_vm.field, "nome"),
                  value: _vm.nome,
                  disabled: !_vm.arePrimaryFieldsEnabled,
                  rules: "required|min:3|max:255",
                  label: "Nome",
                  "lookup-options": _vm.lookupOptions,
                  "lookup-text-field": "nome",
                  "lookup-value-field": "codigo",
                },
                on: {
                  lookup: _vm.handleLookup,
                  input: function ($event) {
                    return _vm.$emit("update:nome", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "RowForm",
        [
          _c(
            "ColBase",
            { attrs: { sm: "" } },
            [
              _c("ControlEmail", {
                attrs: {
                  value: _vm.email,
                  field: _vm.camelCase(_vm.field, "email"),
                  disabled: !_vm.areAllFieldsEnabled,
                  rules: "required",
                  label: _vm.emailLabel,
                  "lookup-options": _vm.lookupOptions,
                  "lookup-text-field": "email",
                  "lookup-value-field": "nome",
                },
                on: {
                  lookup: _vm.handleLookup,
                  input: function ($event) {
                    return _vm.$emit("update:email", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ColBase",
            { attrs: { sm: "" } },
            [
              _c("ControlCelular", {
                attrs: {
                  value: _vm.celular,
                  field: _vm.camelCase(_vm.field, "celular"),
                  disabled: !_vm.areAllFieldsEnabled,
                  label: _vm.celularLabel,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("update:celular", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }