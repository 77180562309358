import { ROUTING_ACCESSES, listCrumbsFlowsManagement } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-managment-edit-page.vue"
  );

export function createFlowsManagementEditRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.REQUESTER],
      crumbs: [...listCrumbsFlowsManagement(), { text: "Edição de Fluxo" }],
      layout: "LayoutNav",
    },
    path: "/flows-management/edit/:itemId",
    // fluxos edit permite definição do tab inicial para suportar o tab dados básicos em edições diretas mas também a aba de interessados após inclusão com posterior redirecionamento automático
    props: route => ({
      initialTab: route.query.initialTab,
      itemId: route.params.itemId,
    }),
  };
}
