<template>
  <TabAccordion
    field="dados"
    header-title="Detalhes"
    :header-subtitle="fluxo.dadosBasicos.status"
    header-icon-name="data"
    v-bind="$attrs"
  >
    <RowForm>
      <ColBase>
        <ControlInput
          v-model="fluxo.dadosBasicos.nome"
          field="nome"
          :disabled="disabled"
          rules="required|min:3|max:100"
          label="Nome"
        />
      </ColBase>
      <ColBase sm>
        <FlowFormBasicTabControlGroup
          v-model="fluxo.dadosBasicos.idGrupo"
          :disabled="disabled"
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase>
        <FlowFormBasicTabControlRequester
          :id-requisitante="fluxo.dadosBasicos.idRequisitante"
          :hidden="hidden"
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase>
        <ControlTextarea
          v-model="fluxo.dadosBasicos.descricao"
          field="descricao"
          :disabled="disabled"
          rules="required|min:3|max:1000"
          label="Descrição"
        />
      </ColBase>
    </RowForm>
    <RowForm>
      <ColBase>
        <ControlCheckbox
          v-model="fluxo.dadosBasicos.isIcp"
          field="icp"
          :disabled="disabled"
          placeholder="Requer uso de certificado ICP-Brasil."
          helper-text="O assinante deve possuir um certificado emitido por uma autoridade ICP-Brasil."
          switch
        />
      </ColBase>
    </RowForm>
    <FlowFormBasicTabControlSequencial
      :is-sequencial.sync="fluxo.dadosBasicos.isSequencial"
      :is-interrompe-se-negativo.sync="fluxo.dadosBasicos.isInterrompeSeNegativo"
      :interessados.sync="fluxo.interessados"
      :disabled="disabled"
    />
  </TabAccordion>
</template>

<script>
import { ColBase, ControlCheckbox, ControlInput, ControlTextarea, RowForm, TabAccordion } from "@/lib";

import FlowFormBasicTabControlGroup from "./flow-form-basic-tab-control-group";
import FlowFormBasicTabControlRequester from "./flow-form-basic-tab-control-requester";
import FlowFormBasicTabControlSequencial from "./flow-form-basic-tab-control-sequencial";

export default {
  components: {
    ColBase,
    ControlCheckbox,
    ControlInput,
    ControlTextarea,
    FlowFormBasicTabControlGroup,
    FlowFormBasicTabControlRequester,
    FlowFormBasicTabControlSequencial,
    RowForm,
    TabAccordion,
  },
  name: "FlowFormBasicTab",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    fluxo: {
      default: null,
      type: Object,
    },
    hidden: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
