var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ButtonIcon", {
    attrs: {
      field: _vm.camelCase("toggle", "feedback", _vm.field),
      hide: !_vm.resposta.parecer,
      icon: _vm.icon,
      tooltip: _vm.tooltip,
    },
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }