var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BSidebar",
    _vm._b(
      {
        attrs: {
          title: _vm.title.toUpperCase(),
          "bg-variant": "none",
          "text-variant": "light",
          "sidebar-class": "side-bar-content-wrapper",
          shadow: true,
          backdrop: true,
          "close-label": "nav-close",
        },
        model: {
          value: _vm.innerIsOpen,
          callback: function ($$v) {
            _vm.innerIsOpen = $$v
          },
          expression: "innerIsOpen",
        },
      },
      "BSidebar",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.groups, function (group) {
      return _c("SideBarGroup", {
        key: group.text,
        attrs: { text: group.text, links: group.links },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }