<template>
  <ButtonIcon
    :field="camelCase('send', 'link', field)"
    :hide="hide || !canSendLink"
    icon="send"
    tooltip="enviar link de assinatura"
    :is-busy="isBusy"
    @click="handleClick"
  />
</template>

<script>
import { isInteressadoWithStatusPendente } from "@/data";
import { ButtonIcon, camelCase, useExecute } from "@/lib";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ButtonIcon },
  computed: {
    ...mapGetters("requisitantes", ["currentRequisitanteId"]),
    canSendLink() {
      return (this.isFluxoIniciado && isInteressadoWithStatusPendente(this.interessado));
    },
  },
  methods: {
    ...mapActions("fluxosAdministrativo", ["sendLink"]),
    camelCase,
    async handleClick() {
      const email = this.interessado.email;
      const linkData = {
        idFluxo: this.idFluxo,
        idRequisitante: this.currentRequisitanteId,
        interessado: this.interessado,
      };

      const saga = {
        onError: ({ message }) => this.$showError(message),
        onExecute: () => this.sendLink(linkData),
        onSuccess: () => this.$notifySuccess(`Link enviado com sucesso para ${email}`),
      };

      this.execute(saga);
    },
  },
  name: "FlowFormStakeholdersTabManagerButtonLink",
  props: {
    field: {
      required: true,
      type: [String, Number],
    },
    hide: {
      default: false,
      type: Boolean,
    },
    idFluxo: {
      default: null,
      type: String,
    },
    interessado: {
      required: true,
      type: Object,
    },
    isFluxoIniciado: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const { execute, isBusy } = useExecute();
    return {
      execute,
      isBusy,
    };
  },
};
</script>
