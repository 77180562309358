var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ButtonIcon", {
    attrs: {
      field: _vm.camelCase("send", "link", _vm.field),
      hide: _vm.hide || !_vm.canSendLink,
      icon: "send",
      tooltip: "enviar link de assinatura",
      "is-busy": _vm.isBusy,
    },
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }