<template>
  <label
    v-bind="$attrs"
    class="text-primary text-uppercase m-0"
  >
    {{ text }}
  </label>
</template>

<script>
export default {
  name: "LabelBase",
  props: {
    text: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
label {
  font-size: var(--w-font-size-20);
}
</style>
