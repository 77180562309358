<template>
  <FragmentBase>
    <ButtonIcon
      :field="camelCase('pull', 'interessado', field)"
      :hide="hide"
      :disabled="disabledPull"
      icon="caret-up"
      tooltip="ordenar para posição anterior"
      @click="handlePull"
    />
    <ButtonIcon
      :field="camelCase('push', 'interessado', field)"
      :hide="hide"
      :disabled="disabledPush"
      icon="caret-down"
      tooltip="ordenar para posição posterior"
      @click="handlePush"
    />
  </FragmentBase>
</template>

<script>
import { ButtonIcon, FragmentBase, camelCase } from "@/lib";

export default {
  components: { ButtonIcon, FragmentBase },
  computed: {
    disabledPull() {
      return this.interessado.ordem === 1;
    },
    disabledPush() {
      return this.interessado.ordem === this.interessados.length;
    },
    hideAll() {
      return this.hide;
    },
  },
  methods: {
    camelCase,
    handlePull() {
      const calcSiblingOrder = ordem => ordem - 1;
      this.reorder(calcSiblingOrder);
    },
    handlePush() {
      const calcSiblingOrder = ordem => ordem + 1;
      this.reorder(calcSiblingOrder);
    },
    reorder(calcSiblingOrder) {
      const currentOrdem = this.interessado.ordem;
      const siblingOrder = calcSiblingOrder(currentOrdem);
      const isSubjectToChange = ordem =>
        ordem === currentOrdem || ordem === siblingOrder;

      const newInteressados = this.interessados.map((a) => {
        if (!isSubjectToChange(a.ordem)) return a;
        return {
          ...a,
          ordem: a.ordem === currentOrdem ? siblingOrder : currentOrdem,
        };
      });
      this.$emit("update:interessados", newInteressados);
    },
  },
  name: "FlowFormStakeholdersTabManagerButtonsOrder",
  props: {
    field: {
      required: true,
      type: [String, Number],
    },
    hide: {
      default: false,
      type: Boolean,
    },
    interessado: {
      default: () => ({}),
      type: Object,
    },
    interessados: {
      default: () => [],
      type: Array,
    },
  },
};
</script>
