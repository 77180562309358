<template>
  <div>
    <PdfManager
      v-model="fluxo.documentos"
      :field="camelCase(field, 'documentos')"
      :pdf-icon="documentoIcon"
      :max-file-quantity="15"
      :hide-upload="disabled"
      :hide-file-del="() => disabled"
      label="Documentos"
      :download-blob="downloadBlob"
      :pdf-upload="pdfUpload"
      :pdf-delete="pdfDelete"
      v-on="$listeners"
    >
      <template #message>
        <InfoText>Documentos do fluxo que devem ser assinados.</InfoText>
      </template>
      <template #actions="{ pdf: documento, index, isUploading }">
        <FlowFormDocumentsTabManagerStamperButton
          :field="camelCase(field, 'documento', 'position', index)"
          :documento="documento"
          :fluxo="fluxo"
          :hide="disabled"
          :disabled="isUploading"
          :download-blob="downloadBlob"
        />
      </template>
    </PdfManager>
  </div>
</template>

<script>
import { hasAllStampsPositioned } from "@/data";
import { InfoText, PdfManager, camelCase } from "@/lib";
import { mapActions } from "vuex";

import FlowFormDocumentsTabManagerStamperButton from "./flow-form-documents-tab-manager-stamper-button";

export default {
  components: {
    FlowFormDocumentsTabManagerStamperButton,
    InfoText,
    PdfManager,
  },
  data() {
    return {
      currentDocumento: null,
    };
  },
  methods: {
    ...mapActions("fluxosAdministrativo", ["uploadDocumento", "deleteDocumento"]),
    camelCase,
    documentoIcon(pdf) {
      const name = { name: "pdf" };
      if (hasAllStampsPositioned(pdf, this.fluxo))
        return {
          ...name,
          fillColor: "var(--w-color-success-50)",
        };

      return {
        ...name,
        fillColor: "var(--w-color-warning-30)",
        tooltip: "posicionamento de assinatura pendente",
      };
    },
    downloadBlob(path) {
      return this.$api.request({ endpoint: path, method: "download" });
    },
    pdfDelete(idDocumento) {
      return this.deleteDocumento({ idDocumento, idFluxo: this.fluxo.id });
    },
    pdfUpload(file) {
      return this.uploadDocumento({ file, idFluxo: this.fluxo.id });
    },
  },
  name: "FlowFormDocumentsTabManager",
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    field: {
      default: "fluxo",
      type: String,
    },
    fluxo: {
      required: true,
      type: Object,
    },
  },
};
</script>
