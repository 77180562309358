<template>
  <div class="dialog-wrapper px-5 py-3 d-flex flex-column">
    <div>
      <div class="d-none d-lg-flex justify-content-end">
        <LogoSerpro
          height="30px"
          width="90px"
        />
      </div>
      <div class="d-flex justify-content-center justify-content-lg-start">
        <LogoApp class="mt-3" />
      </div>
      <div class="d-none d-lg-block">
        <HeaderText
          text="Assine vários documentos num só lugar"
          class="mt-4"
        />
      </div>
    </div>
    <div class="mt-6">
      <component
        :is="dialog"
        :session="session"
        :perfil="perfil"
        :link-token="linkToken"
        :link-id="linkId"
      />
    </div>
  </div>
</template>

<script>
import { HeaderText, LogoApp, LogoSerpro } from "@/lib";
import { mapGetters } from "vuex";

import AuthenticationDialog from "./authentication-dialog";
import ChannelDialog from "./channel-dialog";
import ClientDialog from "./client-dialog";
import EmailDialog from "./email-dialog";
import LinkDialog from "./link-dialog";
import OtpDialog from "./otp-dialog";
import ProfilesDialog from "./profiles-dialog";
import RegistrationDialog from "./registration-dialog";
import SessionDialog from "./session-dialog";
import WelcomeDialog from "./welcome-dialog";

export default {
  components: {
    ChannelDialog,
    EmailDialog,
    HeaderText,
    LinkDialog,
    LogoApp,
    LogoSerpro,
    OtpDialog,
    ProfilesDialog,
    RegistrationDialog,
    SessionDialog,
    WelcomeDialog,
  },
  computed: {
    ...mapGetters("auth", [
      "hasChannels",
      "hasLinkAutenticado",
      "hasPerfis",
      "isClienteSetPending",
      "isOtpSent",
      "isSignedIn",
      "isSignedOut",
      "isWebAuthnAuthenticatorAvailable",
      "isWebAuthnRegistrationPending",
      "webAuthnRegistrationOptions",
    ]),
    dialog() {
      // se o cliente estiver logado vá direto para a tela de boas vindas
      if (this.isSignedIn) return WelcomeDialog;

      // se tem um authenticator registrado ou arbitrariamente dispensou o registro vá e já possui uma lista de perfis para seleção, vá para a escolha do perfil
      if (this.hasPerfis && !this.isWebAuthnRegistrationPending) return ProfilesDialog;

      // se já possui um conjunto de opções de registro válida vá para a tela de registro de dispositivo
      if (this.webAuthnRegistrationOptions && this.isWebAuthnRegistrationPending) return RegistrationDialog;

      // se o otp já foi enviado pelo backend ao canal escolhido vá para a tela onde se informa o otp
      if (this.isOtpSent) return OtpDialog;

      // se possui um dispositivo cadastrado para autenticação vá para a tela de uso dele
      if (this.isWebAuthnAuthenticatorAvailable) return AuthenticationDialog;

      // se tem uma lista de canais disponíveis vá para a lista de canais
      if (this.hasChannels) return ChannelDialog;

      // se ainda não definiu o cliente, vá para a lista de clientes
      if (this.isClienteSetPending) return ClientDialog;

      // se possui um token de link autenticado, vá para a página que provoca a  autenticação direta
      if (this.hasLinkAutenticado) return LinkDialog;

      // se está registrado como fora do sistema vá para a tela de email
      if (this.isSignedOut) return EmailDialog;

      // resolve o status de autenticação inicial do usuário
      return SessionDialog;
    },
  },
  name: "AuthPage",
  props: {
    linkId: {
      default: null,
      type: String,
    },
    linkToken: {
      default: null,
      type: String,
    },
    perfil: {
      default: null,
      type: String,
    },
    session: {
      default: null,
      type: String,
    },
  },
};
</script>

<style>
.dialog-wrapper {
  max-width: var(--w-size-83);
  border-radius: var(--w-size-20);
  background-color: var(--w-color-neutral-10);
  opacity: 0.99;
}

@media (min-width: 576px) {
  .dialog-wrapper {
    box-shadow: var(--w-shadow-primary);
  }
}
</style>
