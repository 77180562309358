import { clone } from "@/lib/objects";

export function createBaseStoreModule(config) {
  const { actions, getters, mutations, state } = config;

  const initialState = clone(state);

  const storeModule = {
    actions,
    getters,
    mutations: {
      clearState(state) {
        Object.assign(state, clone(initialState));
      },
      ...mutations,
    },
    namespaced: true,
    state: clone(initialState),
  };

  return storeModule;
}
