import { SYSLOG_SEVERITIES, isEquallyOrMoreImportant } from "@/lib/severities";

export function consoleTrigger({ message, severity, timestamp }) {
  const trigger = isEquallyOrMoreImportant(severity, SYSLOG_SEVERITIES.ERROR)
    ? console.error
    : isEquallyOrMoreImportant(severity, SYSLOG_SEVERITIES.NOTICE)
      ? console.warn
      : console.info;
  trigger(`[severity: ${severity}] ${timestamp} - ${message}`);
}
