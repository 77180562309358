<template>
  <OverlayBase :is-busy="isBusy">
    <DescriptionText>
      Olá, por favor nos informe o seu <strong>email</strong>.
    </DescriptionText>
    <DialogForm
      :external-errors="lastError"
      @save="execute(listContas, $event)"
    >
      <template #body="{ payload }">
        <ControlEmail
          v-model="payload.email"
          field="email"
          rules="required"
          label="Email"
          autocomplete="webauthn"
          autofocus
        />
      </template>
      <template #actions>
        <slot name="actions" />
        <ButtonPrimary
          label="Verificar"
          field="send"
        />
      </template>
    </DialogForm>
  </OverlayBase>
</template>

<script>
import { ButtonPrimary, ControlEmail, DescriptionText, DialogForm, OverlayBase, useExecute } from "@/lib";
import { mapActions } from "vuex";

export default {
  components: {
    ButtonPrimary,
    ControlEmail,
    DescriptionText,
    DialogForm,
    OverlayBase,
  },
  methods: {
    ...mapActions("auth", ["listContas", "activateWebAuthnConditionalUi"]),
  },
  async mounted() {
    await this.activateWebAuthnConditionalUi();
  },
  name: "EmailDialog",
  setup() {
    const { execute, isBusy, lastError } = useExecute();
    return { execute, isBusy, lastError };
  },
};
</script>
