<template>
  <div>
    <MenuText>
      Confira as Novidades
      <BadgeBase
        :text="prettyHowManyUnread"
        class="float-right"
      />
    </MenuText>
    <MenuItem
      v-for="log in logs"
      :key="log.key"
      to="/changelog"
    >
      {{ log.value }}
    </MenuItem>
  </div>
</template>

<script>
import { BadgeBase, MenuItem, MenuText, truncateString } from "@/lib";
import { mapGetters } from "vuex";

export default {
  components: { BadgeBase, MenuItem, MenuText },
  computed: {
    ...mapGetters("changelog", ["prettyHowManyUnread", "howManyUnread", "recentLogs", "howManyLogs"]),
    isChangelogAvailable() {
      return this.howManyLogs > 0;
    },
    logs() {
      const min = 1;
      const max = 2;
      const titleSize = 30;

      const howManyOnMenu = Math.min(max, Math.max(min, this.howManyUnread));
      const logs = this.recentLogs(howManyOnMenu).map(({ key, title }) => ({
        key,
        value: truncateString(title, { filler: "...", limit: titleSize }),
      }));

      return logs;
    },
  },
  name: "UserMenuChangelog",
};
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}

.current .text {
  color: var(--w-color-primary-30);
}

.dot {
  height: var(--w-size-20);
  width: var(--w-size-20);
  background-color: var(--w-color-neutral-10);
  border-radius: 50%;
  display: inline-block;
}

.current .dot {
  background-color: var(--w-color-primary-30);
}
</style>
