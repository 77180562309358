import { ROUTING_ACCESSES, listCrumbsFlowsArchived } from "@/views/widgets";

const component = () =>
  import(
    /* webpackChunkName: "inner-pages" */
    /* webpackPrefetch: true */
    "./flows-archived-approvals-page.vue"
  );

export function createFlowsArchivedApprovalsRoute() {
  return {
    component,
    meta: {
      access: [ROUTING_ACCESSES.REQUESTER],
      crumbs: [...listCrumbsFlowsArchived(), { text: "Histórico de Respostas" }],
      layout: "LayoutNav",
    },
    path: "/flows-archived/approvals/:itemId",
    props: true,
  };
}
