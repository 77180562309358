<template>
  <ValidationObserver
    ref="formValidationObserver"
    v-slot="{ handleSubmit }"
  >
    <!-- novalidate é incluído para ativar o mecanismo personalizado de validação com bootstrap-vue e vee-validate -->
    <form
      novalidate
      @submit.prevent="handleSubmit(() => onSubmit($event))"
    >
      <BodyActions :show-divider="showActionDivider">
        <template #body>
          <alert-text
            v-if="formError"
            variant="danger"
            icon="error"
            :text="formError"
          />
          <slot
            name="body"
            :payload="payload"
          />
        </template>

        <template #actions>
          <slot
            name="actions"
            :payload="payload"
          />
        </template>
      </BodyActions>
    </form>
  </ValidationObserver>
</template>

<script>
import { AlertText } from "@/lib/components/text";
import { clone } from "@/lib/objects";
import { SYSLOG_SEVERITIES, isMoreImportant } from "@/lib/severities";
import { camelCase } from "@/lib/strings";
import { ValidationObserver } from "@/lib/validation";

import BodyActions from "./body-actions";

export default {
  components: { AlertText, BodyActions, ValidationObserver },
  data() {
    return {
      formError: null,
      payload: clone(this.defaultPayload),
    };
  },
  methods: {
    // infere se o erro refere-se a algum campo do formulário ou deve ser exibido no topo do "form"
    matchErrorWithFormField(error) {
      if (!error || !error.meta) return null;

      // alguns formulários declaram nomes de campo incompatíveis com os nomes adotados em mensagens de erro externas como as retornadas pelo backend. o externalErrorsFieldMap permite a associação explicita nesses casos.
      const errorField = this.externalErrorsFieldMap[error.meta.property] || error.meta.property;

      if (!errorField) return null;

      const formFields = Object.keys(this.$refs.formValidationObserver.fields).concat("form");

      const exactField = formFields.find(f => f === camelCase("validation", errorField));
      if (exactField) return exactField;

      const inferredField = formFields.find(f => f.toLowerCase().includes(errorField.toLowerCase()));
      if (inferredField) return inferredField;

      return null;
    },
    onSubmit(event) {
      // os metadados passados como segundo argumento do evento servem para apoiar comportamentos especiais como formulários com mais de um botão submit.
      const metadata = { event };
      if (event?.target) {
        const submitter = event.submitter || {};
        const formData = new FormData(event.target, submitter);
        metadata.formData = formData;
      }
      this.$emit("save", this.payload, metadata);
    },
  },
  name: "DialogForm",
  props: {
    defaultPayload: {
      default: () => ({}),
      type: Object,
    },
    externalErrors: {
      default: null,
      type: [Object, Error],
    },
    externalErrorsFieldMap: {
      default: () => ({}),
      type: Object,
    },
    showActionDivider: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    defaultPayload(newDefaultPayload) {
      // não pode ser computed pq os descendentes precisam manipular payload
      this.payload = clone(newDefaultPayload);
    },
    externalErrors(error) {
      this.formError = null;
      if (!error) return;

      const severity = error.severity ?? SYSLOG_SEVERITIES.CRITICAL;
      // erros de nível ERROR em formulários são considerados validações de negócio como dado obrigatório pendente e portanto não são encaminhados ao serviço de log
      if (isMoreImportant(severity, SYSLOG_SEVERITIES.ERROR)) {
        this.$logger.log(error);
      }

      const errorField = this.matchErrorWithFormField(error);

      if (errorField === "form") {
        this.formError = error.message;
        return;
      }

      if (errorField) {
        this.$refs.formValidationObserver.setErrors({ [errorField]: error.message });
        return;
      }

      // caso não tenha sido retornado um campo específico para posicionar o erro, exibi-se o erro em um modal de alerta ou de erro dependendo da severidade do erro.
      if (isMoreImportant(severity, SYSLOG_SEVERITIES.ERROR)) {
        this.$showError(error.message);
      }
      else {
        this.$showAlert(error.message);
      }
    },
  },
};
</script>
