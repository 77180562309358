export function injectToken({ client, tokenOrGetter }) {
  client.interceptors.request.use((config) => {
    const token = typeof tokenOrGetter === "function"
      ? tokenOrGetter()
      : tokenOrGetter;
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  });
}
