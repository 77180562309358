import { parseDocumentoDeFluxoItemToRecord, parseDocumentoDeFluxoRecordToItem } from "./parse-fluxo-documento";
import { parseInteressadoDeFluxoItemToRecord, parseInteressadoDeFluxoRecordToItem } from "./parse-fluxo-interessado";

export function parseFluxoRecordToItem(record) {
  const { dadosBasicos, documentos, id, interessados, ...recordRest } = record;
  const { createdAt, interrompeSeNegativo, sequencial, tipoAssinatura, ...basicosRest } = dadosBasicos;

  const interessadosClient = interessados.map(parseInteressadoDeFluxoRecordToItem);

  return {
    id,
    ...recordRest,
    dadosBasicos: {
      dataCriacao: new Date(createdAt),
      isIcp: tipoAssinatura === "QUALIFICADA",
      isInterrompeSeNegativo: interrompeSeNegativo,
      isSequencial: sequencial,
      ...basicosRest,
    },
    documentos: documentos.map(parseDocumentoDeFluxoRecordToItem),
    interessados: interessadosClient,
  };
}

export function parseFluxoItemToRecord(item) {
  const { dadosBasicos, documentos = [], interessados = [], ...itemRest } = item;
  const { isIcp, isInterrompeSeNegativo, isSequencial, ...basicosRest } = dadosBasicos;

  const record = {
    ...itemRest,
    dadosBasicos: {
      interrompeSeNegativo: isInterrompeSeNegativo,
      sequencial: isSequencial,
      tipoAssinatura: isIcp ? "QUALIFICADA" : "AVANCADA",
      ...basicosRest,
    },
    documentos: documentos.map(parseDocumentoDeFluxoItemToRecord),
    interessados: interessados.map(parseInteressadoDeFluxoItemToRecord),
  };
  return record;
}
