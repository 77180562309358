// gera o momento atual como string no formato ISO 8601
export function currentTimestampWithTimezone() {
  const pad = num => (num < 10 ? "0" + num : num);

  const date = new Date();
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  const offset = date.getTimezoneOffset();
  const absOffset = Math.abs(offset);
  const offsetHours = pad(Math.floor(absOffset / 60));
  const offsetMinutes = pad(absOffset % 60);
  const sign = offset <= 0 ? "+" : "-";

  const timezone = `${sign}${offsetHours}:${offsetMinutes}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezone}`;
}
