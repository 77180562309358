<template>
  <ModalBase
    ref="modal"
    show-action-divider
    size="lg"
  >
    <template #body>
      <PdfDocument
        :file-path="filePath"
        :file-name="fileName"
        :download-blob="downloadBlob"
      />
    </template>
    <template #actions="{ cancel }">
      <ButtonPrimary
        label="Fechar"
        @click="cancel()"
      />
    </template>
  </ModalBase>
</template>

<script>
import { ButtonPrimary } from "../buttons";
import { ModalBase } from "../dialogs";
import PdfDocument from "./pdf-document";

export default {
  components: {
    ButtonPrimary,
    ModalBase,
    PdfDocument,
  },
  methods: {
    async open() {
      return this.$refs.modal.show();
    },
  },
  name: "PdfViewModal",
  props: {
    downloadBlob: {
      required: true,
      type: Function,
    },
    fileName: {
      default: null,
      type: String,
    },
    filePath: {
      default: null,
      type: String,
    },
  },
};
</script>
