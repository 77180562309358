var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ControlInput", {
    attrs: {
      value: _vm.nome,
      field: "requisitante",
      hidden: _vm.hidden,
      disabled: true,
      label: "Criado por:",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }