<template>
  <div>
    <FilesManager
      :extensions="['pdf']"
      :download-blob="downloadBlob"
      :field="field"
      :file-icon="pdfIcon"
      :file-upload="pdfUpload"
      :file-delete="pdfDelete"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #message>
        <slot name="message" />
      </template>
      <template #actions="{ file: pdf, index, isUploading }">
        <slot
          name="actions"
          :index="index"
          :pdf="pdf"
          :is-uploading="isUploading"
        />
        <PdfButtonView
          :field="camelCase('view', field, index)"
          :file-path="pdf.path"
          :file-name="pdf.nome"
          :hide="hidePdfView(pdf)"
          :disabled="isUploading"
          :download-blob="downloadBlob"
        />
      </template>
    </FilesManager>
  </div>
</template>

<script>
import { camelCase } from "@/lib/strings";

import FilesManager from "./files-manager";
import PdfButtonView from "./pdf-button-view";

export default {
  components: {
    FilesManager,
    PdfButtonView,
  },
  methods: {
    camelCase,
  },
  name: "PdfManager",
  props: {
    downloadBlob: {
      required: true,
      type: Function,
    },
    field: {
      required: true,
      type: String,
    },
    hidePdfView: {
      default: () => false,
      type: Function,
    },
    pdfDelete: {
      default: null,
      type: Function,
    },
    pdfIcon: {
      default: () => ({ name: "pdf" }),
      type: Function,
    },
    pdfUpload: {
      default: null,
      type: Function,
    },
  },
};
</script>
