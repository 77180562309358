var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ButtonIcon", {
    attrs: {
      field: _vm.camelCase("resposta", _vm.field),
      hide: _vm.hide,
      icon: "list-check",
      tooltip: "visualizar situação das respostas",
    },
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }