var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    { style: _setup.svgStyle, attrs: { viewBox: _setup.svgViewBox } },
    [
      _c(
        "path",
        _vm._b({ attrs: { d: _vm.pathData } }, "path", _vm.pathAttrs, false)
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }